import React from "react";

import {
  Box,
  Divider,
  Heading,
  OrderedList,
  ListItem,
  VStack,
  Text,
} from "@chakra-ui/react";

import Header from "components/Header";
import { useTranslation } from "react-i18next";

const PrivacyView = ({ isLogged }) => {
  const { t } = useTranslation();
  return (
    <>
      {!isLogged && <Header />}
      <Box width="100%" paddingX="50px" my={!isLogged ? "12" : "20"}>
        <Heading width="100%" size="lg" textAlign="left">
          {t('PRIVACY_POLICY')}
        </Heading>
        <Divider mt="4" />
        <VStack mt="10" spacing={"8"} alignItems="flex-start" maxW={"1090px"}>
          <Text>
            {t('TUTORIA_PRIVACY_POLICY.INTRO')}
            <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" style={{ textDecoration: "underline" }}>{t("TUTORIA_PRIVACY_POLICY.REFER_GOOGLE_POLICY")}</a>
          </Text>
          <Text>
            {t('TUTORIA_PRIVACY_POLICY.INTRO2')}
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.CONFIDENTIALITY.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.CONFIDENTIALITY.DESCRIPTION')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.INTEGRITY.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.INTEGRITY.DESCRIPTION')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.AVAILABILITY.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.AVAILABILITY.DESCRIPTION')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.AUDITABILITY.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.AUDITABILITY.DESCRIPTION')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.DATA_COLLECTION.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.DATA_COLLECTION.DESCRIPTION')}
              <br />
              <br />
              <Text pl="4" as="b" fontSize={"md"}>
                {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.TITLE')}
              </Text>
              <br />
              <OrderedList pl="4">
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('ACCOUNT_INFORMATION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.ACCOUNT_INFORMATION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('NAME')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.NAME')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('INSTITUTION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.INSTITUTION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('EMAIL')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.EMAIL')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('USAGE_INFORMATION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.USAGE_INFORMATION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('DEVICE_INFORMATION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.DEVICE_INFORMATION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('LOGS_INFORMATION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.LOGS_INFORMATION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('COOKIES_INFORMATION')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.COOKIES_INFORMATION')}
                </ListItem>
              </OrderedList>
              {t('TUTORIA_PRIVACY_POLICY.INFORMATIONS.END')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.INTRO')}
              <Text pl="4">
                <br />
                a. {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.A')} <br />
                b. {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.B')} <br />
                c. {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.C')} <br />
                d. {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.D')}
              </Text>
              <br />
              {t('TUTORIA_PRIVACY_POLICY.ACCESS_AND_CONTROL.END')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.INTRO')}
              <Text pl="4">
                a. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.A')} <br />
                b. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.B')} <br />
                c. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.C')} <br />
                d. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.D')} <br />
                e. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.E')} <br />
                f. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.F')} <br />
                g. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.G')} <br />
                h. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.H')} <br />
                i. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.I')} <br />
                j. {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.J')}
              </Text>
              <br />
              {t('TUTORIA_PRIVACY_POLICY.USAGE_PERSONAL_DATA.END')}
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.INTRO')}
              <br />
              <OrderedList pl="4">
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.1.TITLE')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.1.DESCRIPTION')}
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.2.TITLE')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.2.DESCRIPTION')}
                </ListItem>
                {/* <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.3.TITLE')}:
                  </Text>{" "}
                  {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.3.DESCRIPTION')}
                </ListItem> */}
              </OrderedList>
              {t('TUTORIA_PRIVACY_POLICY.SHARE_DATA.END')}
              <br />
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.LAW.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.LAW.DESCRIPTION')}
              <br />
            </Text>
          </Text>
          <Text as="b" fontSize={"lg"}>
            {t('TUTORIA_PRIVACY_POLICY.TERMS.TITLE')}
            <br />
            <Text fontWeight={"normal"}>
              {t('TUTORIA_PRIVACY_POLICY.TERMS.INTRO')}
              <br />
              <OrderedList pl="4">
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.TERMS.1')}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.TERMS.2')}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text pl="4" as="b" fontSize={"md"}>
                    {t('TUTORIA_PRIVACY_POLICY.TERMS.3')}
                  </Text>
                </ListItem>
              </OrderedList>
              <br />
              {t('TUTORIA_PRIVACY_POLICY.TERMS.END')}
            </Text>
          </Text>
        </VStack>
      </Box>
    </>
  );
};

export default PrivacyView;
