import { Flex, Heading, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";
import ButtonBackOverview from "components/ButtonBackOverview";

const MyEvaluationTitle = () => {
  const { t } = useTranslation();

  const { evaluationNumber, activityNumber } = useContext(EvaluationContext);
  return (
    <Flex direction="column" align="flex-start">
      <ButtonBackOverview />
      <Heading
        className="unselectable t-evaluation-text t-title"
        width="100%"
        size="lg"
        textAlign="left"
        paddingBottom="1rem"
      >
        {t('MY_CORRECTION')}
      </Heading>
      <Text
        className="unselectable"
        marginY="2"
        width="100%"
        textAlign="left"
        fontSize="lg"
      >
        {t('YOU_ARE_CORRECTING_ACTIVITY')} <b>{evaluationNumber}</b>{" "}
        {activityNumber}
      </Text>
    </Flex>
  );
};

export default MyEvaluationTitle;
