import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Textarea,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Colors from "constants/Colors";
import EvaluationTypes from "constants/EvaluationTypes";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ColorPickerPopover from "../ColorPickerPopover";
import { EvaluationActions, EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";
import { llmFeedbackSugestion } from "service/LlmServices";
import MyEvaluationTable from "./MyEvaluationTable";
import { FaWandMagicSparkles } from "react-icons/fa6";

const MyEvaluationDrawer = ({
  onDismiss,
  visible,
  range,
  onClickDelete,
  onClickSave,
}) => {
  const {
    dispatch,
    setRecommendedTags,
    allTags,
    evaluationQuestionDescription,
    evaluationQuestionTitle,
  } = useContext(EvaluationContext);
  const { t } = useTranslation();
  const [color, setColor] = useState("");
  const [tagName, setTagName] = useState("");
  const [type, setType] = useState("");
  const [explanation, setExplanation] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const showFeedback = JSON.parse(window.localStorage.getItem("currentUser")).ai_feedback;

  useEffect(() => {
    setTagName(range?.tag_name ?? "");
    setColor(range?.color ?? Colors.list[0]);
    setType(range?.type ?? EvaluationTypes.ERROR);
    setExplanation(range?.explanation ?? "");
  }, [range]);

  const onSave = useCallback(() => {
    const payload = {
      ...range,
      color: color || Colors.list[0],
      type: type || EvaluationTypes.ERROR,
      tag_name: tagName || range.tag_name,
      explanation: explanation || range.explanation,
      isRecommendation: false,
    };

    if (range?.isRecommendation) {
      setRecommendedTags((state) => state.filter((r) => range.id !== r.id));
      dispatch({
        type: EvaluationActions.addRange,
        payload: {...payload, recommendation_accepted: true},
      });
      onClickSave?.();
      return;
    }

    dispatch({
      type: EvaluationActions.updateRange,
      payload,
    });
    onClickSave?.();
  }, [range, color, type, tagName, explanation]);

  const onClickTag = useCallback((tag) => {
    setColor(tag.color);
    setType(tag.type);
    setTagName(tag.tag_name);
    setExplanation(tag.explanation);
  }, []);

  const onChangeTagName = useCallback((name) => {
    setTagName(name);
  }, []);

  const onChangeExplanation = useCallback((explanation) => {
    setExplanation(explanation);
  }, []);

  const onChangeType = useCallback((type) => {
    setType(type);
  }, []);

  const feedbackSuggestion = async () => {
    setFeedbackLoading(true);
    setExplanation("");
    await llmFeedbackSugestion(
      evaluationQuestionTitle,
      evaluationQuestionDescription,
      !range.isRecommendation ? range.content : range.content_to_mark,
      setExplanation,
      setFeedbackLoading,
      type,
      range.id
    );
  };

  return (
    <Drawer placement="bottom" isOpen={visible} onClose={onDismiss}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton onClick={onDismiss} />
        <DrawerHeader borderBottomWidth="1px">
          <Flex justify="space-between">
            <Text>{t("EDIT_TAG")}</Text>
            <Flex>
              <Button
                colorScheme="red"
                mr={2}
                onClick={() => onClickDelete(range)}
              >
                {t("DELETE")}
              </Button>
              <Button colorScheme="blue" onClick={() => onSave()} mr={8}>
                {t("SAVE")}
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Flex justify="space-between">
            <Flex style={{ width: "45%" }} flexDirection="column">
              <Flex w="100%" alignItems="flex-end">
                <Box my="8px" mr="30px">
                  <Text mb="8px">{t("NAME")}: </Text>
                  <Input
                    value={tagName}
                    onChange={(e) => onChangeTagName(e.target.value)}
                  />
                </Box>
                <Box my="8px" mr="30px">
                  <Text my="8px" mr="5px">
                    {t("COLOR")}:{" "}
                  </Text>
                  <ColorPickerPopover
                    color={color}
                    onColorSelected={setColor}
                  />
                </Box>
                <Box my="8px">
                  <Text my="8px" mr="5px">
                    {t("TYPE")}:{" "}
                  </Text>
                  <Select
                    height="10"
                    backgroundColor="gray.50"
                    width={"auto"}
                    border="none"
                    value={type}
                    onChange={(e) => onChangeType(e.target.value)}
                  >
                    <option value={EvaluationTypes.ERROR}>{t("ERROR")}</option>
                    <option value={EvaluationTypes.HIT}>{t("HIT")}</option>
                    <option value={EvaluationTypes.PARTIAL_HIT}>
                      {t("PARTIAL_HIT")}
                    </option>
                  </Select>
                </Box>
              </Flex>
              <Box>
                <Text my="8px">Feedback: </Text>
                <Textarea
                  value={explanation}
                  onChange={(e) => onChangeExplanation(e.target.value)}
                  minHeight={120}
                />
                {showFeedback && 
                <Flex justify="flex-end" mt={2}>
                  <Button
                    size="sm"
                    isLoading={feedbackLoading}
                    onClick={feedbackSuggestion}
                    rightIcon={<FaWandMagicSparkles />}
                  >
                    {t("FEEDBACK_SUGGESTION")}
                  </Button>
                </Flex>}
              </Box>
            </Flex>
            <Flex style={{ width: "50%" }}>
              <MyEvaluationTable
                title={t("REUSE_TAGS")}
                tooltipText={t("REUSE_THIS_TAG")}
                tags={allTags}
                onAdd={(tag) => onClickTag(tag)}
                descWarning={t("REUSE_TAGS_DESC")}
              />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MyEvaluationDrawer;
