import { useCallback, useContext, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Select
} from "@chakra-ui/react";
import { EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

const MyEvaluationChoice = ({ choice, index }) => {
  const { t } = useTranslation();
  const { correctAnswersIndex, updateCorrectAnswers } =
    useContext(EvaluationContext);

  const selectEnum = {
    0: t('INCORRECT'),
    1: t('CORRECT'),
  };

  const ascii = 0x61 + index;

  const isCorrectAnswer = useMemo(
    () => correctAnswersIndex.includes(index),
    [correctAnswersIndex, index]
  );

  const [value, setValue] = useState(isCorrectAnswer ? 1 : 0);

  const onClickAnswer = useCallback(() => {
    if (isCorrectAnswer) {
      updateCorrectAnswers(correctAnswersIndex.filter((i) => i !== index));
      return;
    }
    updateCorrectAnswers([...correctAnswersIndex, index]);
  }, [correctAnswersIndex, index, isCorrectAnswer, updateCorrectAnswers]);

  const onChangeSelectOptionHandler = (event) => {
    setValue(Number(event.target.value))
    onClickAnswer();
  };

  const OptionList = () =>
    Object.keys(selectEnum).map((index) => (
      <option value={index} key={uuidv4()}>{selectEnum[index]}</option>
    ));

  return (
    <Box paddingY="2">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <Box>{String.fromCharCode(ascii)}</Box>
          <Box>
            {") "}
            {choice}
          </Box>
        </Flex>
        <Flex>
          <Select
            variant="filled"
            size="sm"
            value={value}
            onChange={onChangeSelectOptionHandler}
            borderRadius="8px"
            minW="200px"
          >
            <OptionList />
          </Select>
        </Flex>
      </Flex>
      <Divider w="100%" mt="4" />
    </Box>
  );
};

export default MyEvaluationChoice;
