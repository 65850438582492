export const saveInfoToStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getInfoFromStorage = (key, callback) => {
  callback(JSON.parse(localStorage.getItem(key)));
};

export const returnInfoFromStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const invalidateNavbarKeys = () => {
  localStorage.removeItem("correction-history");
};
