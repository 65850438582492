import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { post } from "../utils/fetch";

import ContainerLayout from "../components/ContainerLayout";
import SearchBar from "../components/SearchBar";

import OnboardVideoModal from "components/OnboardVideoModal/OnboardVideoModal";
import { sendFeedbackVideo } from "constants/OnboardVideoUrl";

import {
  Accordion,
  Alert,
  AlertIcon,
  Button,
  Flex,
  Heading,
  Divider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { fetchUserData } from "../utils/user";

import { StudentAccordion } from "../components/StudentAccordion";

import SendFeedbackScreenSkeleton from "components/SendFeedbackScreen/Skeleton/SendFeedbackScreenSkeleton";

import SendFeedbackModal from "components/SendFeedbackScreen/SendFeedbackModal";

import feedbackModalSend from "media/feedback-modal-send.png";
import feedbackModalSent from "media/feedback-modal-sent.png";

import { FEEDBACK_MODEL, HOME, QUESTION } from "constants/Routes";
import { useTranslation } from "react-i18next";
import ButtonBackOverview from "components/ButtonBackOverview";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const SendFeedbackScreen = () => {
  const history = useHistory();
  const { workId } = useNavigationCorrection()
  const [filterText, setFilterText] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [hasFeedbackSent, setHasFeedbackSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formId,] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalsubtitle] = useState("");
  const [modalImage, setModalImage] = useState("");
  const [modalCancelTitle, setModalCancelTitle] = useState("");
  const [modalContinueTitle, setModalContinueTitle] = useState("");
  const [modalCancelAction, setModalCancelAction] = useState(() => { });
  const [modalContinueAction, setModalContinueAction] = useState(() => { });
  const [isUserOnboardEnabled] = useState(
    localStorage.getItem("user-onboarding-send-feedback")
  );
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    isOpen: isOpenSendModal,
    onOpen: onOpenSendModal,
    onClose: onCloseSendModal,
  } = useDisclosure();

  useEffect(() => {
    handleUserOnboardingVideo();
  }, [isUserOnboardEnabled]);

  const handleUserOnboardingVideo = () => {
    if (isUserOnboardEnabled === null) {
      onOpenModal();
      localStorage.setItem("user-onboarding-send-feedback", true);
    }
  };

  useEffect(() => {
    fetchUserData().then((r) => {
      post(`/api/courses/correction/feedback/`, {
        work_id: workId,
      })
        .then((res) => {
          setFeedbackData(res.data);
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          toast({
            description: t('FEEDBACKS.FEEDBACK_ERROR'),
            duration: 3000,
            status: "error",
            isClosable: true,
            position: "bottom",
          });
          history.push(QUESTION)
        });
    });
  }, []);

  const onSendStudentFeedbackHandler = (feedbackData, setHasFeedbackSent) => {
    setIsModalLoading(true);
    post(`/api/courses/correction/feedback/submit/`, {
      work_id: workId,
      student_email: feedbackData.student_email,
    })
      .then(() => {
        setHasFeedbackSent(true);
        setIsModalLoading(false);
        handleModalSent();
      })
      .catch(() => {
        setIsModalLoading(false);
        handleModalSent();
      });
  };

  const onSendByStudentFeedbackHandler = (feedbackData, setHasFeedbackSent) => {
    let subtitle =
      t('SEND_FEEDBACK_CONFIRM').replace(
        "@student",
        feedbackData.student_name ?? t('UNREGISTERED_STUDENT')
      );
    onOpenSendModal();
    setModalTitle(t('SENDING_INDIVIDUAL_FEEDBACK'));
    setModalsubtitle(subtitle);
    setModalCancelTitle(t('CANCEL'));
    setModalContinueTitle(t('YES_SEND'));
    setModalImage(feedbackModalSend);
    setModalContinueAction(() => () => {
      onContinueModalHandler(true, feedbackData, setHasFeedbackSent);
    });
    setModalCancelAction(() => () => {
      onCloseSendModal();
    });
  };

  const onSendAllStudentsFeedbackEmailHandler = () => {
    onOpenSendModal();
    setModalTitle(t('SENDING_ALL_FEEDBACKS'));
    setModalsubtitle(
      t('SEND_FEEDBACKS_CONFIRM')
    );
    setModalCancelTitle(t('CANCEL'));
    setModalContinueTitle(t('YES_SEND'));
    setModalImage(feedbackModalSend);
    setModalContinueAction(() => () => {
      onContinueModalHandler(false, null, null);
    });
    setModalCancelAction(() => () => {
      onCloseSendModal();
    });
  };

  const onContinueModalHandler = (
    isIndividual,
    feedbackData,
    setHasFeedbackSent
  ) => {
    if (!isIndividual) {
      sendFeedbackStudents();
    } else {
      onSendStudentFeedbackHandler(feedbackData, setHasFeedbackSent);
    }
  };

  const handleModalSent = () => {
    setModalTitle(t('FEEDBACKS.FEEDBACKS_SENT'));
    setModalsubtitle("");
    setModalCancelTitle(t('GO_TO_OVERVIEW'));
    setModalContinueTitle(t('CONCLUDE'));
    setModalImage(feedbackModalSent);
    setModalContinueAction(() => () => {
      onCloseSendModal();
    });
    setModalCancelAction(() => () => {
      history.push(HOME);
    });
  };

  const sendFeedbackStudents = () => {
    setIsModalLoading(true);
    post(`/api/courses/correction/feedback/submit/`, {
      work_id: workId,
    })
      .then(() => {
        setHasFeedbackSent(true);
        setIsModalLoading(false);
        handleModalSent();
      })
      .catch(() => {
        toast({
          description: t('FEEDBACKS.SUBMIT_ERROR'),
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "bottom",
        });
      });
  };

  const onChangeSearchHandler = (text) => {
    setFilterText(text);
  };

  return (
    <>
      <Flex spacing="4" direction="row" paddingLeft="70px">
        <ContainerLayout>
          <Flex flexDirection="column" align="flex-start" justify="start" w="100%">
            <ButtonBackOverview />
            <Heading
              className="unselectable"
              width="100%"
              size="lg"
              textAlign="left"
            >
              {t('FINISH_CORRECTION')}
            </Heading>
          </Flex>

          <Divider marginBottom="4" marginTop="2" />
          <Flex
            w="100%"
            h="50px"
            justifyContent="space-between"
            alignItems="center"
            mb="6"
          >
            <SearchBar
              placeholder={t('FILTER_BY_STUDENT')}
              onChangeText={(event) =>
                onChangeSearchHandler(event.target.value)
              }
            />
            {!isLoading && (
              <Flex alignItems="end">
                <Button mr={4} onClick={() => history.push({ pathname: FEEDBACK_MODEL })}>{t('FEEDBACK_MODEL')}</Button>
                <Button
                  colorScheme="blue"
                  onClick={onSendAllStudentsFeedbackEmailHandler}
                >
                  {t('SEND_FEEDBACK')}
                </Button>
              </Flex>
            )}
          </Flex>
          {isLoading && <SendFeedbackScreenSkeleton />}
          {!isLoading && (
            <>
              <OnboardVideoModal
                isOpen={isOpenModal}
                onClose={onCloseModal}
                videoUrl={sendFeedbackVideo}
              />
              <Flex w="100%">
                <Accordion defaultIndex={[0]} allowMultiple w="100%">
                  {feedbackData.filter(item => item.student_name.toLowerCase().includes(filterText.toLowerCase())).map((feedback) => {
                    return (
                      <StudentAccordion
                        onSendStudentFeedbackHandler={onSendByStudentFeedbackHandler}
                        feedbackData={feedback}
                        key={formId}
                      />
                    );
                  })}
                </Accordion>
              </Flex>
            </>
          )}
          {hasFeedbackSent && (
            <Alert status="success" variant="subtle">
              <AlertIcon />
              {t('FEEDBACKS.FEEDBACKS_SENT')}
            </Alert>
          )}
          <SendFeedbackModal
            isOpen={isOpenSendModal}
            onClose={onCloseSendModal}
            isLoading={isModalLoading}
            title={modalTitle}
            subtitle={modalSubtitle}
            image={modalImage}
            cancelTitle={modalCancelTitle}
            cancelButtonAction={modalCancelAction}
            continueTitle={modalContinueTitle}
            continueButtonAction={modalContinueAction}
          />
        </ContainerLayout>
      </Flex>
    </>
  );
};

export default SendFeedbackScreen;
