import React from "react";

import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const generateColor = (percentage) => {
  if (percentage < 50) {
    return "red";
  } else if (percentage >= 50 && percentage < 70) {
    return "yellow";
  } else {
    return "green";
  }
};

const CorrectionTag = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      colorScheme={generateColor(parseInt(props.percentage))}
      borderRadius="10px"
      alignSelf="flex-start"
      w="max-content"
    >
      {props.percentage}% {t('CORRECTED')}
    </Tag>
  );
};

export default CorrectionTag;
