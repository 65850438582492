export const homeVideo =
  "https://www.youtube.com/embed/5UNlIxfW55Y?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com";
export const sendFeedbackVideo =
  "https://www.youtube.com/embed/jx0XmhkxN4U?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1";
export const plagiaryVideo =
  "https://www.youtube.com/embed/gpUvxrVqpDA";
export const extraMarkerVideo =
  "https://www.youtube.com/embed/ur_hkeuX9mI?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&theme=light&fs=0&color=white&autohide=0&controls=0&disablekb=1";
export const markerCreationVideo =
  "https://www.youtube.com/embed/5ZyXSQBMNu0";
export const markerReuse =
  "https://www.youtube.com/embed/eu-6X6eiZAk";
