import React from "react";

import {
  Button,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Spinner,
  Spacer,
} from "@chakra-ui/react";

const SendFeedbackModal = ({
  isOpen = () => {},
  onClose = () => {},
  title = "",
  subtitle = "",
  image = "",
  cancelTitle = "",
  cancelButtonAction = () => {},
  continueTitle = "",
  continueButtonAction = () => {},
  isLoading = false,
}) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p="8">
        {isLoading && (
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Spinner />
          </Flex>
        )}
        {!isLoading && (
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Image src={image} alignItems={"center"} />
            <ModalHeader mt="6">{title}</ModalHeader>
            <ModalBody pb={6}>
              <Flex
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Text textAlign={"center"}>{subtitle}</Text>
              </Flex>
            </ModalBody>
            <ModalFooter w="100%">
              <Flex
                w="100%"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Button
                  onClick={cancelButtonAction}
                  variant="outline"
                  size="sm"
                >
                  {cancelTitle}
                </Button>
                <Spacer w="40px" />
                <Button
                  colorScheme={"blue"}
                  onClick={continueButtonAction}
                  size="sm"
                >
                  {continueTitle}
                </Button>
              </Flex>
            </ModalFooter>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SendFeedbackModal;
