import React from "react";

import { Avatar, Spinner, Tag, TagLabel } from "@chakra-ui/react";

const StudentTag = ({
  avatar = "",
  name = "name",
  description = "description",
  mousePointerStyle = "",
  className = "",
  isLoading = false,
  didShowBackground = true,
}) => {
  return (
    <>
      <Tag
        w="max-content"
        maxW={["290px", "600px", "800px"]}
        px={2}
        py={1.5}
        colorScheme="white"
        height={8}
        backgroundColor={didShowBackground ? "gray.50" : "transparent"}
        borderRadius="full"
        className={className}
        style={{ cursor: mousePointerStyle }}
      >
        <Avatar
          src={avatar}
          size="sm"
          name={name}
          ml={-1}
          mr={2}
          boxSize="25px"
        />
        <TagLabel fontSize="md">{description}</TagLabel>
        {isLoading && <Spinner color={"blue.700"} ml={"2"} />}
      </Tag>
    </>
  );
};

export default StudentTag;
