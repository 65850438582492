import React, { useState } from "react";

import {
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Wrap,
  WrapItem,
  Box,
} from "@chakra-ui/react";

import ContainerLayout from "../components/ContainerLayout";

import SearchBar from "components/SearchBar";

import videoJson from "../constants/OnboardVideoList.json";
import VideoCard from "components/VideoCard";
import { useTranslation } from 'react-i18next';

const HelpView = () => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const handleOnSearchBarChange = (newValue) => {
    setSearchValue(newValue.target.value);
  };

  const VideoList = videoJson
    .filter((video) =>
      t(video["video-name"])?.toLowerCase().includes(searchValue.toLowerCase())
    )
    .map((video) => (
      <WrapItem key={video["video-url"]}>
        <VideoCard
          videoName={t(video["video-name"])}
          videoUrl={video["video-url"]}
        />
      </WrapItem>
    ));

  return (
    <>
      <ContainerLayout>
        <Box width="100%" paddingLeft="70px">
          <Heading width="100%" size="lg" textAlign="left">
            {t('TUTORIAL_VIDEOS')}
          </Heading>
          <Tabs width="100%" mt={4}>
            <TabList></TabList>
            <TabPanels>
              <SearchBar
                placeholder={t('SEARCH_HERE')}
                onChangeText={handleOnSearchBarChange}
                value={searchValue}
              />
              <Wrap SimpleGrid minChildWidth={"436px"} spacing="40px" mt="40px">
                {VideoList}
              </Wrap>
            </TabPanels>
          </Tabs>
        </Box>
      </ContainerLayout>
    </>
  );
};

export default HelpView;
