import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
  Divider,
  useToast,
} from "@chakra-ui/react";

import { fetchUserData } from "utils/user";

import { googleLogout } from "../components/auth/Logout";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { post } from "utils/fetch";

export const ClassSelection = ({ classes, callback }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedList, setSelectedList] = useState(
    classes?.map((element) => ({ ...element, checked: false }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    };
  }, []);

  const handleCancelGoogle = async () => {
    const { email } = await fetchUserData();
    await googleLogout(email);
    history.push("/");
    document.location.reload(true);
  }

  const handleSelectCheck = (checked, classId) => {
    setSelectedList(selectedList?.map((element) => {
      if (element.id === classId) {
        return ({ ...element, checked })
      }
      return element
    }));
  };

  const handleAllSelection = (isChecked) => {
    setSelectedList(classes?.map((element) => ({ ...element, checked: isChecked })));
  };

  const handleImportClassesClick = async () => {
    setIsLoading(true);
    const selectedClasses = selectedList.filter((item) => item.checked);
    await post("api/google/save_courses", { courses: selectedClasses })
    .then(() => {
      onClose()
      callback(selectedClasses)
      setIsLoading(false)
    })
    .catch(() => {
      setIsLoading(false)
      toast({
        description: t('FEEDBACKS.WORKS_FAILED'),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      });
    });
      
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        onEsc={handleCancelGoogle}
        w="500px"
        h="500px"
        isCentered
        borderRadius="xl"
      >
        <ModalOverlay />
        <ModalContent boxShadow="sm" borderRadius="xl">
          <ModalHeader w="100%">
            <Text p="4" textAlign="center">
              {t('SELECT_CLASSES_TO_IMPORT')}
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex
              as={"form"}
              flexDirection="column"
              pl="4"
              pr="4"
              width="100%"
              height="200px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Checkbox
                my={"2"}
                px={"2"}
                w={"full"}
                fontSize={30}
                fontWeight={600}
                onChange={(e) => handleAllSelection(e.target?.checked)}
              >
                {t('SELECT_ALL')}
              </Checkbox>
              <Divider mb={3} />
              <Flex
                w={"full"}
                h={"full"}
                flexDir={"column"}
                alignItems={"center"}
                overflowY={"scroll"}
                px={"2"}
              >
                {classes.map((googleClass) => {
                  return (
                    <Box w="100%" alignItems="flex-start" key={googleClass.id}>
                      <Checkbox
                        key={googleClass.id}
                        value={googleClass.id}
                        isChecked={selectedList.find(item => item.id === googleClass.id).checked}
                        size={"md"}
                        my={"2"}
                        w={"full"}
                        onChange={(e) =>
                          handleSelectCheck(
                            e.target?.checked,
                            googleClass.id
                          )
                        }
                      >
                        {googleClass.name}
                      </Checkbox>
                      <Divider
                        border="2px"
                        borderColor="white"
                        bgColor="white"
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter mb="4" p="4" mx="8" justifyContent="space-between">
            <Button
              w="45%"
              size="md"
              type="submit"
              onClick={handleCancelGoogle}
            >
              {t('CANCEL')}
            </Button>
            <Button
              w="45%"
              size="md"
              type="submit"
              colorScheme={"blue"}
              isDisabled={!selectedList.some(item => item.checked)}
              onClick={handleImportClassesClick}
              isLoading={isLoading}
            >
              {t('CONCLUDE')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
