import React, { useState, useEffect } from "react";

import { Box, Button, Divider, Flex, Input, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const InfoSectionItem = ({
  itemName = "",
  itemValue = "-",
  itemCallback = () => { },
  isEditingEnabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  const handleEditingState = (newState) => {
    setIsEditing(newState);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleSaveClick = () => {
    itemCallback(inputValue);
    handleEditingState(false);
  };

  useEffect(() => {
    setInputValue(itemValue);
  }, []);

  return (
    <Box py="4">
      <Flex justifyContent={"space-between"} alignItems="center">
        <Box w="50%">
          <Text fontWeight={"bold"}>{itemName}</Text>
          {isEditing && (
            <Input
              w="100%"
              mt="2"
              placeholder={inputValue}
              value={inputValue}
              onChange={(event) => handleInputChange(event.target.value)}
              size="sm"
            />
          )}
          {!isEditing && <Text>{itemValue}</Text>}
        </Box>
        {isEditing && (
          <Flex>
            <Button
              size="sm"
              mx="2"
              onClick={() => {
                handleEditingState(false);
              }}
            >
              {t('CANCEL')}
            </Button>
            <Button
              size="sm"
              mx="2"
              colorScheme="blue"
              onClick={() => {
                handleSaveClick();
              }}
            >
              {t('SAVE')}
            </Button>
          </Flex>
        )}
        {!isEditing && (
          <Button
            size="sm"
            colorScheme="blue"
            disabled={!isEditingEnabled}
            onClick={() => {
              handleEditingState(!isEditing);
            }}
          >
            {t('EDIT')}
          </Button>
        )}
      </Flex>
      <Divider mt="4" />
    </Box>
  );
};

export default InfoSectionItem;
