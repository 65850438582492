import {
  Box,
  Divider,
  IconButton,
  Wrap,
  WrapItem,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import {
  AiOutlineBold,
  AiOutlineCode,
  AiOutlineItalic,
  AiOutlineUnderline,
} from "react-icons/ai";

export const inlineStyles = [
  { label: "B", style: "BOLD", icon: <Icon as={AiOutlineBold} boxSize="4" /> },
  {
    label: "I",
    style: "ITALIC",
    icon: <Icon as={AiOutlineItalic} boxSize="4" />,
  },
  {
    label: "U",
    style: "UNDERLINE",
    icon: <Icon as={AiOutlineUnderline} boxSize="4" />,
  },
  {
    label: "code ",
    style: "CODE",
    icon: <Icon as={AiOutlineCode} boxSize="4" />,
  },
];

const InlineStyleControls = ({ currentInlineStyle, onToggle }) => {
  return (
    <Box
      w="100%"
      h="10"
      p="2"
      bgColor="gray.100"
      borderRadius=".5rem .5rem 0px 0px"
      justifyContent="flex-start"
    >
      <Wrap
        spacing="2"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {inlineStyles.map((type) => (
          <React.Fragment key={type.label}>
            <WrapItem>
              <IconButton
                w="6"
                h="6"
                bgColor={
                  currentInlineStyle.has(type.style) ? "white" : "gray.100"
                }
                icon={type.icon}
                key={type.label}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onToggle(type.style);
                }}
              >
                {type.label}
              </IconButton>
            </WrapItem>
            <WrapItem>
              <Divider orientation="vertical" />
            </WrapItem>
          </React.Fragment>
        ))}
      </Wrap>
    </Box>
  );
};

export default InlineStyleControls;
