import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";

import { useState } from "react";

import { post } from "../utils/fetch";
import { fetchUserData } from "../utils/user";

import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import { postSaveForms } from "service/ReviewPageServices";

export const ClassMenu = ({ course_id, isClassroomTest, callback }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleUpdate = async () => {
    const { email: mail } = await fetchUserData();

    setLoading(true);
    try {
      await postSaveForms(mail, course_id, () => { });
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  const handleDelete = async () => {
    const ret = await post(
      "api/courses/",
      {
        course_id,
      },
      "DELETE"
    );

    if (ret?.success) {
      callback(course_id);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={loading ? <Spinner /> : <HiOutlineDotsVertical />}
          variant={isClassroomTest ? "solid" : "outline"}
          disabled={loading}
        />
        <MenuList>
          <MenuItem icon={<RepeatIcon />} onClick={handleUpdate}>
            {t('UPDATE_CLASS')}
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
            {t('DELETE_CLASS')}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
