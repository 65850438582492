import { React, useCallback } from "react";

import MixedTags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

import "./styles.css";
import { transformTag } from "./utils";
import { useTranslation } from "react-i18next";

const FeedbackModelInput = ({
  value = "",
  blocId = 0,
  handleUpdateFeedbackBlocText = () => { },
  data = [],
}) => {
  const { t } = useTranslation();

  let tagList = [
    {
      identifier: "STUDENT_NAME",
      text: t('STUDENT_NAME'),
      value: t('RETURN_STUDENT_NAME'),
    },
    {
      identifier: "DELIVER_DATE",
      text: t('DELIVERY_DATE'),
      value: t('RETURN_DELIVERY_DATE'),
    },
    {
      identifier: "RELEASE_DATE",
      text: t('ACTIVITY_DATE'),
      value: t('RETURN_ACTIVITY_DATE'),
    },
    {
      identifier: "ACTIVITY_SCORE",
      text: t('ACTIVITY_NOTE'),
      value: t('RETURN_ACTIVITY_NOTE'),
    },
  ];

  const onChange = useCallback((e) => {
    handleUpdateFeedbackBlocText(blocId, data, e.detail.value);
  }, []);

  const settings = {
    mode: "mix",
    pattern: "@",
    tagTextProp: "text",
    duplicates: true,
    whitelist: tagList,
    transformTag: (tagData) => transformTag(tagData),
    dropdown: {
      enabled: 0,
      position: "text",
      mapValueTo: "text",
      highlightFirst: true,
      maxItems: 30,
    },
  };

  return (
    <MixedTags
      onChange={onChange}
      value={value}
      settings={settings}
      showFilteredDropdown={"true"}
      defaultValue={value}
    />
  );
};

export default FeedbackModelInput;
