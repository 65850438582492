import React from "react";

import {
  CircularProgress,
  CircularProgressLabel,
  Spinner,
  Text,
} from "@chakra-ui/react";

export const LoadingClasses = ({feedback}) => (
  <div>
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Text fontWeight="500" textColor="blue.600">
        {feedback}
      </Text>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptycolor="gray.200"
        color="blue.500"
        size="xl"
      />
    </div>
  </div>
);

export const LoadingSavingForms = ({ percentage, feedback }) => (
  <div>
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Text fontWeight="500" textColor="blue.600">
        {feedback}
      </Text>
      <CircularProgress
        thickness="8px"
        emptycolor="gray.200"
        color="blue.500"
        value={percentage}
      >
        <CircularProgressLabel>
          <Text fontWeight="500" textColor="blue.600">
            {percentage + "%"}
          </Text>
        </CircularProgressLabel>
      </CircularProgress>
    </div>
  </div>
);
