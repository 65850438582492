import { Box, Flex, Input, Spinner, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { EvaluationActions, EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const MyEvaluationScore = () => {
  const { maxScore, dispatch, evaluation, setScore } = useContext(EvaluationContext);
  const [localScore, setLocalScore] = useState("");
  const [hasLoaded, setHasLoaded] = useState(true);
  const { t } = useTranslation();

  var timer = null;

  useEffect(() => {
    if (evaluation.score === null) {
      setLocalScore("");
    }
    else {
      setLocalScore(evaluation.score);
    }
  }, [evaluation.score]);

  const onClickSave = useCallback(
    (value) => {
      dispatch({
        type: EvaluationActions.updateEvaluation,
        payload: { score: Number(value) },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localScore, dispatch]
  );

  const handleUpdateScore = (e) => {
    setLocalScore(e.target.value);
    setScore(e.target.value)
  };

  useEffect(() => {
    if (localScore) {
      setHasLoaded(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setTimeout(function () {
        onClickSave(localScore);
        setHasLoaded(true);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [localScore]);

  return (
    <>
      {
        maxScore &&
        <Box>
          <Text className="title" mb={0}>
            {t('NOTE_FOR_QUESTION')}
          </Text>
          <Text fontSize="sm" textColor="gray.400" mb={2}>
            {t('QUESTION_WORTH_UP_TO')} {maxScore} {t('POINTS')}
          </Text>
          <Flex paddingBottom="2" justifyContent="center" alignItems="center">
            <Input
              type="number"
              marginEnd="4"
              onChange={(e) => handleUpdateScore(e)}
              value={localScore}
            />
          </Flex>
          {!hasLoaded && <Flex alignItems={"center"}><Spinner color="gray.400" boxSize={2} mr={2} /><Text fontSize="sm" textColor="gray.400">{t('SAVING')}...</Text></Flex>}
        </Box>
      }
    </>
  );
};

export default MyEvaluationScore;
