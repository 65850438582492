import MyEvaluationContent from "components/MyEvaluation/MyEvaluationContent";
import EvaluationContextProvider from "components/MyEvaluation/MyEvaluationContext";

const MyEvaluationView = (props) => (
  <EvaluationContextProvider rootProps={props}>
    <MyEvaluationContent />
  </EvaluationContextProvider>
);

export default MyEvaluationView;
