import { Flex } from "@chakra-ui/react";
import { useContext } from "react";
import MyEvaluationChoice from "./MyEvaluationChoice";
import { EvaluationContext } from "./MyEvaluationContext";

const MyEvaluationChoices = () => {
  const { evaluation } = useContext(EvaluationContext);
  return (
    <Flex width="100%" flexDirection="column" marginY="4">
      {evaluation.possible_choices.map((choice, index) => (
        <MyEvaluationChoice choice={choice} index={index} key={choice} />
      ))}
    </Flex>
  );
};

export default MyEvaluationChoices;
