export const EVALUATE = "/evaluate";
export const HOME = "/";
export const SETTINGS = "/settings/";
export const HELP = "/help";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot";
export const PASSWORD_RESET = "/reset/:uid/:token";
export const QUESTION = "/questions";
export const SEND_FEEDBACK = "/send";
export const FEEDBACK_MODEL = "/models";
export const REVIEW_FEEDBACK = "/review";
export const SUPPORT = "https://forms.gle/VyY8iT6CyyNju2yB6";
export const PRIVACY_POLICY = "/privacy";
