import { Box, Button, Divider, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import FeedbackModelInput from "components/FeedbackModelInput";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ContainerLayout from "../components/ContainerLayout";
import { get, post } from "../utils/fetch";
import { useTranslation } from "react-i18next";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const FeedbackModels = () => {
  const history = useHistory();
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const { workId } = useNavigationCorrection()
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [blocStructure, setBlocStructure] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
  const { t, i18n } = useTranslation();
  let blocJson = [];

  const generateDataStructure = useMemo(() => {
    let blocArray = [
      {
        "bloc-type": "feedback",
        id: 0,
        props: {
          "text-content":
            i18n.language === "pt" ? 'Olá, [[{"identifier":"STUDENT_NAME","text":"Nome do estudante","value":"Retorna nome do estudante","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]]! Você alcançou a nota [[{"identifier":"ACTIVITY_SCORE","text":"Nota na atividade","value":"Retorna a nota do estudante na atividade","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]] na atividade entregue no dia [[{"identifier":"DELIVER_DATE","text":"Data de entrega","value":"Retorna a data de entrega da atividade","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]] e disponibilizada no dia [[{"identifier":"RELEASE_DATE","text":"Data da atividade","value":"Retorna a data da atividade","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]].' :
              'Hi, [[{"identifier":"STUDENT_NAME","text":"Student name","value":"Return student name","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]]! You reached the grade [[{"identifier":"ACTIVITY_SCORE","text":"Activity grade","value":"Return the student grade on the activity","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]] on the activity delivered on day [[{"identifier":"DELIVER_DATE","text":"Delivery date","value":"Return delivery date","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]] and available on the day [[{"identifier":"RELEASE_DATE","text":"Activity date","value":"Return activity date","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]].'
          ,
        },
      },
    ];
    for (let question = 1; question <= numberOfQuestions; question++) {
      blocArray.push({
        "bloc-type": "question",
        id: question,
        props: {
          "question-number": question.toString(),
        },
      });
    }

    blocArray.push({
      "bloc-type": "feedback",
      id: blocArray.length,
      props: {
        "text-content":
          i18n.language === 'pt' ? 'Até logo! [[{"identifier":"STUDENT_NAME","text":"Nome do estudante","value":"Retorna nome do estudante","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]].' :
            'Bye! [[{"identifier":"STUDENT_NAME","text":"Student name","value":"Return student name","style":"--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)","prefix":"@"}]].'
        ,
      },
    });
    return blocArray;
  }, [i18n.language, numberOfQuestions]);

  useEffect(() => {
    post("api/courses/details/", {
      view: "question",
      work_id: workId,
    }).then((res) => {
      setNumberOfQuestions(res.data.length);
    });
  }, [])

  useEffect(() => {
    get(`/api/courses/correction/feedback/${workId}`)
      .then((res) => {
        if (res?.template === undefined) {
          setBlocStructure(generateDataStructure)
          setFirstTime(true)
        } else {
          setBlocStructure(res.template);
        }
      })
      .catch((err) => { console.log(err) });
  }, []);

  useEffect(() => {
    if (firstTime) {
      setBlocStructure(generateDataStructure)
    }
  }, [numberOfQuestions, i18n.language]);

  useEffect(() => {
    blocJson = [...blocStructure];
  }, [blocStructure]);

  const postModel = (workId, blocList) => {
    post(`/api/courses/correction/feedback/save/`, {
      work_id: workId,
      template: blocList,
    })
      .then((res) => {
        setIsSaveButtonLoading(false);
      })
      .catch((err) => {
        setIsSaveButtonLoading(false);
      });
  };

  const handleUpdateFeedbackBlocText = (blocId, data, newText) => {
    const tempBlocJson = blocJson.map((bloc) => {
      if (bloc.id === blocId) {
        bloc.props["text-content"] = newText;
      }
      return bloc;
    });
    setBlocStructure(tempBlocJson);
    blocJson = tempBlocJson;
  };

  const handleOnSaveModel = () => {
    setIsSaveButtonLoading(true);
    postModel(workId, blocStructure);
  };

  const BlocList = blocStructure?.map((bloc) => {
    if (bloc["bloc-type"] === "question") {
      return <Box my="4" bgColor="gray.50" borderRadius="lg" key={bloc.id}>
        <Text p="4" color="gray.500">
          {t('EXPLANATION_ABOUT_EMAIL')}
        </Text>
        <Text p="4" color="back" fontWeight="bold">
          {t('QUESTION_FEEDBACK_BLOCK')} {bloc.props["question-number"]}
        </Text>
      </Box>
    } else {
      return (
        <Stack
          key={bloc.id}
          w="100%"
          my="4"
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={-4}
        >
          <FeedbackModelInput
            handleUpdateFeedbackBlocText={handleUpdateFeedbackBlocText}
            data={blocStructure}
            blocId={bloc.id}
            value={bloc.props["text-content"]}
          />
        </Stack>
      );
    }
  });

  return (
    <Flex spacing="2" direction="row" paddingLeft="70px">
      <ContainerLayout>
        <Flex w="100%" justify="flex-start">
          <Button leftIcon={<ArrowBackIcon />} colorScheme='blue' variant='link' mb={2} onClick={() => {
            history.goBack()
          }}>
            {t('BACK')}
          </Button>
        </Flex>
        <Flex justify="space-between" w="100%">

          <Heading
            className="unselectable"
            width="100%"
            size="lg"
            textAlign="left"
          >
            {t('FEEDBACK_MODEL')}
          </Heading>
          <Button
            colorScheme="blue"
            isLoading={isSaveButtonLoading}
            onClick={handleOnSaveModel}
            ml={4}
          >
            {t('SAVE_MODEL')}
          </Button>
        </Flex>
        <Divider marginBottom="4" marginTop="2" />
        <Flex
          w="100%"
          justifyContent="flex-start"
          alignItems="center"
          mb="2"
          paddingTop="6"
        >
          <Box w="100%" border="1px" borderColor="gray.100" borderRadius="lg">
            <Flex
              w="100%"
              h="12"
              top="0"
              p="6"
              justifyContent="space-between"
              alignItems="center"
              bg="gray.100"
              borderTopRadius="lg"
            >
              <Text>{t('FEEDBACKS.ADD_VARIABLES')}</Text>
            </Flex >
            <Box w="100%" p="6" borderRadius="lg">
              {BlocList}
            </Box>
          </Box>
        </Flex>
      </ContainerLayout>
    </Flex>
  );
};

export default FeedbackModels;
