export const llmFeedbackSugestion = async (
  question,
  answer,
  tag_content,
  callback,
  setLoading,
  tag_type,
  tag_id
) => {
  const lang = localStorage.getItem("user-lang") || "pt-BR";
  const response = await fetch("api/generate-feedback", {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Token ${window.localStorage.getItem("tokenAuth")}`,
    },
    body: JSON.stringify({
      question,
      answer,
      tag_content,
      tag_type,
      lang,
      tag_id,
    }),
  });

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  const loopRunner = true;
  if (reader) {
    while (loopRunner) {
      // Here we start reading the stream, until its done.
      const { value, done } = await reader.read();

      const decodedChunk = decoder.decode(value, { stream: true });
      callback((answer) => answer + decodedChunk);
      if (done) {
        setLoading(false);
        break;
      }
    }
  }
  setLoading(false);
};
