import React from "react";

import { Flex, Skeleton } from "@chakra-ui/react";

const CorrectionProgressSkeleton = () => {
  return (
    <Flex
      flexDirection="column"
      w="260px"
      overflow="hidden"
      justifyContent="center"
    >
      <Skeleton h="450px" />
    </Flex>
  );
};

export default CorrectionProgressSkeleton;
