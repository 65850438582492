import React, { useMemo } from "react";
import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text
} from "@chakra-ui/react";

import * as ROUTES from "../constants/Routes";
import { Link, useHistory } from "react-router-dom";

import UserLogout, { googleLogout } from "../components/auth/Logout";

import { ChevronDownIcon } from "@chakra-ui/icons";

import tutoriaLogo from "../media/tutoria-logo.png";
import { fetchUserData } from "utils/user";
import { useTranslation } from 'react-i18next';
import ChangeLang from "./ChangeLang";
import { useQuery } from "utils/useQuery";

const NavBar = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const hasParams = useMemo(() => {
    return query.get("token") && query.get("action")
  }, [])

  return (
    <Flex
      w="100vw"
      top={0}
      h="55px"
      p="6"
      boxShadow="sm"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="6"
      position="fixed"
      zIndex="1030"
      backgroundColor="#F7F7FE"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Link to={"/"}>
          <Image w="100px" ml="16" src={tutoriaLogo} alt="Tutoria Logo" />
        </Link>
        <Flex marginX="12">
          {
            !query.get("work_id") &&
            <Button
              marginX="2"
              onClick={() => history.push(ROUTES.HOME)}
              colorScheme="blue"
              variant="ghost"
            >
              {t('MY_CLASSES')}
            </Button>
          }
          {!hasParams && <Button
            marginX="2"
            colorScheme="blue"
            variant="ghost"
            onClick={() =>
              history.push(ROUTES.SETTINGS)
            }
          >
            {t('SETTINGS')}
          </Button>}
          <Button
            marginX="2"
            onClick={() => history.push(ROUTES.HOME)}
            colorScheme="blue"
            variant="ghost"
            display="none"
          >
            Dashboard
          </Button>
          <Button
            marginX="2"
            onClick={() => history.push(ROUTES.HELP)}
            colorScheme="blue"
            variant="ghost"
          >
            {t('HELP')}
          </Button>
          <Button
            marginX="2"
            onClick={() => window.open(ROUTES.SUPPORT, "_blank")}
            colorScheme="blue"
            variant="ghost"
          >
            {t('SUPPORT')}
          </Button>
          <Button
            marginX="2"
            onClick={() => history.push(ROUTES.PRIVACY_POLICY)}
            colorScheme="blue"
            variant="ghost"
          >
            {t('PRIVACY_POLICY')}
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <ChangeLang />
        {!hasParams && <Menu>
          <MenuButton
            as={Button}
            backgroundColor="#F7F7FE"
            alignItems="center"
            justifyContent="space-around"
            rightIcon={<ChevronDownIcon />}
          >
            <Flex justifyContent="center" alignItems="center">
              <Text marginX="2">{t('MY_ACCOUNT')}</Text>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={async () => {
                const { email } = await fetchUserData();
                await googleLogout(email);
                history.push("/");
                document.location.reload(true);
              }}
            >
              {t('END_GOOGLE_SESSION')}
            </MenuItem>
            <MenuItem onClick={() => UserLogout()}>
              {t('END_TUTORIA_SESSION')}
            </MenuItem>
          </MenuList>
        </Menu>}
      </Flex>
    </Flex>
  );
};

export default NavBar;
