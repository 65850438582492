import React from "react";

import {
  Alert,
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";

import CorrectionTag from "../components/CorrectionTag";

import { TiWarning } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import { WarningIcon } from '@chakra-ui/icons'

const formatting = (date) => {
  const year = date.slice(0, date.indexOf("-"));
  date = date.slice(date.indexOf("-") + 1);
  const month = date.slice(0, date.indexOf("-"));
  const day = date.slice(date.indexOf("-") + 1);
  return day.concat("/" + month + "/" + year);
};

const ActivityCard = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Box
        maxW="350px"
        minW="350px"
        minH="200px"
        maxHeight="600px"
        borderWidth="1px"
        bg={props.isClassroomTest && "gray.100"}
        borderRadius="lg"
        overflow="hidden"
        padding={4}
        cursor={props.numAnswers > 0 ? "pointer" : "not-allowed"}
        onClick={props.numAnswers > 0 && props.onClick}
        _hover={props.numAnswers > 0 && {
          boxShadow:
            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;",
          borderWidth: "0px",
        }}
      >
        <Box>
          <Flex
            width="100%"
            height="160px"
            direction={"column"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              width="100%"
              direction={"row"}
              justifyContent={props.hasAvatar ? "left" : "space-between"}
              alignItems="center"
            >
              {props.hasAvatar && (
                <Avatar
                  marginRight="4"
                  size="sm"
                  name={props.avatarName}
                  src={props.avatarSrc}
                />
              )}
              {props.avatarGroup}
              <Heading noOfLines="2" as="h3" size="medium" width="65%">
                {props.activityName}
              </Heading>
              {props.hasNotPercentage ? (
                <></>
              ) : (
                <CorrectionTag percentage={props.correctionPercentage} />
              )}
            </Flex>
            <Text
              width="100%"
              textAlign="left"
              noOfLines="3"
              fontSize="15px"
              fontWeight="light"
              dangerouslySetInnerHTML={{ __html: props.activityDescription }}
            />

            <Flex
              width="100%"
              direction={"row"}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Text fontSize="13px" fontWeight="light">
                {props.activityDate && `${t('DUE_DATE')}: ${formatting(props.activityDate)}`}
              </Text>
              {!props.numAnswers > 0 && <Text
                display="flex"
                alignItems="center"
                fontSize="16px"
                color="#EF3007"
              ><WarningIcon mr={2} />{t('WITHOUT_ANSWERS')}</Text>}
            </Flex>
          </Flex>
        </Box>
        {props.activityName === "Estudante não cadastrado" && (
          <>
            <Divider my="2" />
            <Alert
              status="warning"
              borderRadius={"md"}
              bgColor="white"
              borderWidth={"1px"}
              borderColor={"#f6c000"}
            >
              <TiWarning color="#f6c000" size={"40px"} />
              <Text ml="2" fontSize={"xs"}>
                {t('FEEDBACKS.STUDENTS_WITHOUT_EMAIL')}
              </Text>
            </Alert>
          </>
        )}
      </Box>
    </div>
  );
};

export default ActivityCard;
