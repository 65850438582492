import React from "react";
import {
  Flex,
  Text,
  Link
} from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';

const FooterGoogle = ({position}) => {
  const { t } = useTranslation();

  return (
    <Flex
      w="100vw"
      bottom={0}
      position={position}
      h="20px"
      p="6"
      boxShadow="sm"
      alignItems="center"
      justifyContent="space-between"
      zIndex="1030"
      backgroundColor="#F7F7FE"
    >
        <Text>
            {t('FOOTER.1')}
            <Link textDecoration="underline" href="https://developers.google.com/terms/api-services-user-data-policy">
                {t('FOOTER.2')}
            </Link>
            {t('FOOTER.3')}
        </Text>
    </Flex>
  );
};

export default FooterGoogle;
