import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SEND_FEEDBACK, QUESTION } from "constants/Routes";

const NavigationCorrectionContext = createContext({});

const handleSaveStorage = (label, value) => {
    window.localStorage.setItem(label, value)
}

const handleGetValueStorage = (label) => {
    return window.localStorage.getItem(label)
}

export const NavigationCorrectionProvider = ({ children }) => {
    const history = useHistory()
    const [filterBy, setFilterBy] = useState(handleGetValueStorage("filterBy") || "to_correct");
    const [listEvaluation, setListEvaluation] = useState([]);
    const [classroomId, setClassroomId] = useState(handleGetValueStorage("classroomId") || null);
    const [workId, setWorkId] = useState(handleGetValueStorage("workId") || null);
    const [responseId, setResponseId] = useState(handleGetValueStorage("responseId") || null);
    const [questionId, setQuestionId] = useState(handleGetValueStorage("questionId") || null);
    const [questionType, setQuestionType] = useState(null);
    const [currentEvaluation, setCurrentEvaluation] = useState(null);
    const [navigationList, setNavigationList] = useState([]);

    useEffect(() => {
        handleSaveStorage("workId", workId);
        handleSaveStorage("responseId", responseId)
        handleSaveStorage("questionId", questionId)
        handleSaveStorage("classroomId", classroomId);
        handleSaveStorage("filterBy", filterBy);
    }, [workId, classroomId, responseId, questionId, filterBy])

    const handleSaveIds = (nextEval) => {
        setResponseId(nextEval.response_id)
        setQuestionId(nextEval.question_id)
    }

    useEffect(() => {
        const allAnswersList = listEvaluation.map(evaluation => {
            if (evaluation.question_type === "MULTIPLE_CHOICE") {
                return {
                    ...evaluation,
                    answers: [
                        evaluation.answers[0]
                    ]
                }
            }
            return evaluation;
        }).flatMap(item => item.answers)
        setNavigationList(filterBy === "corrected" ? allAnswersList.filter(item => item.score !== null) : allAnswersList.filter(item => item.score === null))
        setCurrentEvaluation(allAnswersList.find(item => item.response_id === responseId && item.question_id === questionId))
   }, [listEvaluation, responseId, questionId, filterBy])

    const handleNextPage = () => {
        setQuestionType(null)
        let nextEval = navigationList.find((item, index) => index === navigationList.findIndex(item => item === currentEvaluation) + 1);
        if (nextEval) {
            handleSaveIds(nextEval)
        }
        else {
            history.push(SEND_FEEDBACK)
        }
    }

    const handlePreviousPage = () => {
        setQuestionType(null)
        let prevEval = navigationList.find((_, index) => index === navigationList.findIndex(item => item === currentEvaluation) - 1);
        if (prevEval) {
            handleSaveIds(prevEval)
        }
        else {
            history.push(QUESTION)
        }
    }

    return (
        <NavigationCorrectionContext.Provider
            value={{
                setListEvaluation,
                setResponseId,
                setQuestionId,
                responseId,
                questionId,
                setFilterBy,
                handleNextPage,
                handlePreviousPage,
                setWorkId,
                workId,
                setClassroomId,
                classroomId,
                questionType,
                setQuestionType
            }}
        >
            {children}
        </NavigationCorrectionContext.Provider>
    )
}

export const useNavigationCorrection = () => {
    const context = useContext(NavigationCorrectionContext);

    if (!context) throw new Error('"useNavigationCorrection" must be used inside a ParamsMoodleProvider.');

    const {
        setListEvaluation,
        setResponseId,
        setQuestionId,
        responseId,
        questionId,
        setFilterBy,
        handleNextPage,
        handlePreviousPage,
        setWorkId,
        workId,
        setClassroomId,
        classroomId,
        questionType,
        setQuestionType
    } = context;

    return {
        setListEvaluation,
        setResponseId,
        setQuestionId,
        responseId,
        questionId,
        setFilterBy,
        handleNextPage,
        handlePreviousPage,
        setWorkId,
        workId,
        setClassroomId,
        classroomId,
        questionType,
        setQuestionType
    };
}