export function generateQuestionTagScore(numberOfQuestions, tagList) {
  let tempList = [];
  let currentQuestion = 0;

  for (
    currentQuestion = 1;
    currentQuestion <= parseInt(numberOfQuestions);
    currentQuestion++
  ) {
    tempList.push({
      identifier: `QUESTION${currentQuestion}_SCORE`,
      text: `Nota na questão ${currentQuestion}`,
      value: `Retorna a nota do estudante na questão ${currentQuestion}`,
    });
  }
  return tagList.concat(tempList);
}

export function transformTag(tagData) {
  tagData.style =
    "--tag-bg: #EEEEEE; --tag-hover: #E9F8FE; --tags-focus-border-color: rgb(181, 237, 255)";
  return tagData;
}
