export function BrazilIcon({ size, style, ...others }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 13.4375C20 15.3359 18.9459 16.875 16.875 16.875H3.125C1.05406 16.875 0 15.3359 0 13.4375V6.5625C0 4.66406 1.05406 3.125 3.125 3.125H16.875C18.9459 3.125 20 4.66406 20 6.5625V13.4375Z" fill="#128807" />
            <path d="M17.8564 10.2469L10.047 15.7344L2.2373 10.2469L10.047 4.7594L17.8564 10.2469Z" fill="#F9CB38" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.1278 11.2024C12.7722 12.4108 11.6594 13.3308 10.3994 13.4558C8.71597 13.6243 7.24316 12.5543 6.88504 10.9002C6.77908 10.4141 6.79259 9.90962 6.92441 9.42991C6.97347 9.37679 7.04129 9.36397 7.10879 9.3546C7.60116 9.28624 8.0989 9.26458 8.59535 9.28991C9.12504 9.31991 9.65035 9.39335 10.161 9.53772C10.685 9.68616 11.1925 9.87991 11.6716 10.1374C12.0321 10.329 12.3765 10.5495 12.7013 10.7968C12.8113 10.8821 12.9235 10.9658 13.0291 11.0568C13.0763 11.0974 13.1135 11.1412 13.1278 11.2024Z" fill="#002776" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.10059 8.9409C7.50402 8.03934 8.18434 7.43903 9.12465 7.15715C11.0184 6.58934 12.9612 7.8409 13.2428 9.79278C13.2865 10.0943 13.2884 10.3968 13.2422 10.699C13.1943 10.7168 13.1587 10.6915 13.1259 10.6643C12.7827 10.3839 12.4165 10.1328 12.0312 9.91371C11.7345 9.74534 11.4263 9.59831 11.1087 9.47371C11.0525 9.45153 10.9818 9.43996 10.9625 9.36465C10.9828 9.3284 11.0084 9.29684 11.039 9.26746C11.05 9.25621 11.0659 9.24528 11.0512 9.22653C11.0462 9.22059 11.0359 9.22059 11.0272 9.22153C11.0037 9.22403 11.0068 9.24028 11.0078 9.25746C11.0097 9.3134 10.9772 9.34934 10.9343 9.37809C10.8584 9.39528 10.7856 9.37246 10.724 9.33965C10.5478 9.24715 10.3528 9.21559 10.1662 9.16403C9.87527 9.08496 9.57652 9.04028 9.2784 8.99371C9.04047 8.95572 8.80096 8.9284 8.56059 8.91184C8.34184 8.89653 8.11902 8.89871 7.89871 8.91371C7.6584 8.93059 7.41746 8.94746 7.1784 8.97621C7.16341 8.9784 7.14811 8.97624 7.13431 8.96998C7.12052 8.96372 7.10881 8.95362 7.10059 8.9409Z" fill="#002776" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9651 9.34532C10.9638 9.38345 10.9979 9.37845 11.0185 9.38657C11.8255 9.7005 12.576 10.1438 13.2404 10.6991C13.2212 10.8705 13.1833 11.0393 13.1273 11.2025C12.7363 10.8781 12.336 10.5691 11.8932 10.3166C10.4935 9.51939 8.99477 9.20345 7.39258 9.37282C7.23633 9.38876 7.08008 9.41001 6.92383 9.42907C6.96414 9.26001 7.01539 9.09407 7.09945 8.94032C8.26914 8.78251 9.41852 8.8722 10.5463 9.22126C10.6863 9.2647 10.8198 9.3347 10.9691 9.34782L10.9651 9.34439" fill="#FEFEFE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4217 11.3593C12.3277 11.3493 12.4298 11.4646 12.3348 11.4621C12.252 11.4609 12.2477 11.3946 12.207 11.3578C12.2383 11.3253 12.2936 11.3187 12.3111 11.2681C12.323 11.3215 12.3733 11.3328 12.4217 11.3593Z" fill="#D9DFEA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.87309 11.9374C8.78465 11.9477 8.85872 12.044 8.78465 12.0455C8.70997 12.0471 8.67153 11.9918 8.65653 11.9346C8.65122 11.9184 8.72309 11.8821 8.7584 11.8552C8.76278 11.9118 8.82153 11.9093 8.87309 11.9374Z" fill="#E9ECF3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0432 11.7634C9.92039 11.8427 10.0195 11.6634 9.94727 11.6809C9.9707 11.6434 10.037 11.6521 10.0413 11.5946C10.0726 11.6271 10.0991 11.6646 10.1488 11.6715C10.0779 11.6618 10.1551 11.8193 10.0432 11.7634Z" fill="#EDEFF5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.50566 9.65776C7.47629 9.7137 7.4541 9.7762 7.36504 9.7387C7.31941 9.71963 7.36816 9.65713 7.31348 9.6512L7.41473 9.56213C7.4216 9.57682 7.42973 9.59088 7.4366 9.60557C7.44473 9.64276 7.51879 9.59338 7.50566 9.65776Z" fill="#F0F2F6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.78959 11.0155C7.74303 11.0302 7.77647 11.1527 7.67647 11.0993C7.66647 11.0943 7.62272 11.1184 7.63084 11.1043C7.65709 11.053 7.59834 11.028 7.60741 10.9871C7.66209 11.0109 7.65897 10.9109 7.71491 10.9377C7.71897 10.9409 7.71897 10.9534 7.72178 10.9615C7.72772 11.0002 7.80334 10.9518 7.78959 11.0155Z" fill="#ECEFF4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.29812 11.3775C7.20219 11.411 7.27375 11.295 7.21875 11.296C7.24438 11.2744 7.27313 11.2341 7.29875 11.2356C7.32938 11.2372 7.3575 11.275 7.38563 11.2966C7.34375 11.3106 7.38781 11.4241 7.29812 11.3775Z" fill="#D9DFEA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0056 12.4835C10.904 12.5297 11.0028 12.3931 10.935 12.411C10.9281 12.3588 11.0128 12.3903 11.0022 12.3353L11.0937 12.4088C11.0565 12.4256 11.0937 12.5319 11.0056 12.4835Z" fill="#D8DEEA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6559 12.408C11.5719 12.443 11.6234 12.3299 11.5781 12.3187C11.6087 12.299 11.6409 12.278 11.6709 12.2577C11.6734 12.304 11.7369 12.2871 11.7438 12.328C11.6966 12.3309 11.7456 12.4327 11.6559 12.408ZM12.2403 11.9874C12.1447 12.0359 12.2153 11.9087 12.1606 11.914C12.1716 11.8734 12.2344 11.8887 12.2381 11.8418L12.3278 11.909C12.2731 11.9074 12.3403 12.0415 12.2403 11.9874Z" fill="#DCE1EC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.36902 11.4041C8.26902 11.4606 8.33434 11.3341 8.28809 11.3316C8.29277 11.285 8.3634 11.305 8.36652 11.2556C8.3759 11.3012 8.43652 11.2881 8.44965 11.3291C8.38996 11.3203 8.47496 11.4637 8.36902 11.4041Z" fill="#D4DAE7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.77512 10.4547C8.71855 10.4509 8.79105 10.3619 8.72168 10.3675C8.73355 10.3175 8.78699 10.3278 8.81637 10.3019C8.83137 10.3334 8.87387 10.3406 8.87605 10.3859C8.88105 10.4688 8.80762 10.4259 8.77512 10.4547Z" fill="#F2F4F7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4218 11.1147C10.3352 11.1643 10.3808 11.0534 10.3408 11.0412C10.368 11.0187 10.3943 10.9959 10.4218 10.9728C10.4236 11.019 10.5121 11.0087 10.4871 11.0443C10.4721 11.0656 10.5205 11.1628 10.4218 11.1147Z" fill="#E6E9F1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0346 10.8362C9.93582 10.8462 10.0377 10.7565 9.97363 10.7587C9.99863 10.7352 10.0227 10.6952 10.0508 10.6927C10.0752 10.6902 10.1033 10.7299 10.1299 10.7518C10.0952 10.7852 10.1005 10.8715 10.0346 10.8362Z" fill="#EAEDF3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.432 11.5656C12.4523 11.6193 12.4923 11.6265 12.5235 11.6456C12.4917 11.6815 12.4792 11.7365 12.4129 11.7365C12.3451 11.7371 12.391 11.6856 12.3757 11.6615C12.3379 11.6015 12.4776 11.6521 12.432 11.5656Z" fill="#E4E8F0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7246 12.6543C11.7008 12.6896 11.7171 12.7233 11.6539 12.7246C11.5817 12.7258 11.6049 12.673 11.6017 12.6568C11.5958 12.6299 11.6371 12.6043 11.6608 12.6096C11.6814 12.6133 11.7214 12.6249 11.7246 12.6543Z" fill="#CED5E4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.34779 11.6531C8.29623 11.659 8.33904 11.7265 8.27373 11.7243C8.21404 11.7224 8.22279 11.6821 8.21623 11.6577C8.20592 11.6237 8.25623 11.6246 8.26748 11.599C8.29123 11.6156 8.31529 11.6321 8.34779 11.6531Z" fill="#DAE0EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.584 12.0099C11.6062 11.9933 11.6284 11.978 11.6518 11.9624C11.6537 11.9971 11.7284 11.9771 11.7034 12.0215C11.693 12.0393 11.7184 12.088 11.6537 12.0837C11.5955 12.0796 11.6093 12.039 11.584 12.0099Z" fill="#D8DEE9" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3469 12.1171C11.3356 12.1787 11.3006 12.1887 11.2622 12.1959C11.1969 12.2068 11.246 12.1577 11.2353 12.139C11.2078 12.0921 11.2772 12.1012 11.2853 12.0706C11.3035 12.0943 11.345 12.0937 11.3469 12.1171Z" fill="#CBD2E3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9813 10.0906C10.9595 10.1009 10.9885 10.1531 10.9416 10.1381C10.906 10.1259 10.8623 10.109 10.8654 10.0653C10.8666 10.0484 10.9091 10.0343 10.9329 10.0197C10.937 10.0512 11.0045 10.0409 10.9813 10.0906Z" fill="#D1D8E6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.7499 11.4612C12.6911 11.4844 12.7177 11.4359 12.7074 11.4219C12.668 11.3684 12.7458 11.3762 12.7499 11.3444C12.7739 11.3603 12.8208 11.3784 12.8189 11.3922C12.8176 11.41 12.8099 11.4268 12.7972 11.4395C12.7845 11.4522 12.7677 11.4599 12.7499 11.4612Z" fill="#DADFEA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.72758 10.9844C9.72758 11.0206 9.8007 11.0063 9.77602 11.0516C9.76602 11.07 9.78727 11.1194 9.73664 11.1091C9.6982 11.1012 9.65945 11.0788 9.6582 11.0406C9.6582 11.0228 9.70258 11.0037 9.72758 10.9844Z" fill="#CAD2E2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7279 12.115C10.6992 12.1015 10.6979 12.0637 10.6998 12.0456C10.7029 12.0215 10.7351 11.989 10.7626 12.0022C10.7795 12.0103 10.8273 12.0056 10.8157 12.0493C10.8051 12.0912 10.7632 12.0972 10.7279 12.115Z" fill="#D0D7E5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9375 12.0599C11.9388 12.0596 11.9606 12.0293 11.9853 12.0287C12.0031 12.0281 12.0212 12.0649 12.0403 12.0856C12.0147 12.0928 12.0366 12.1399 11.9859 12.1393C11.9403 12.1384 11.9403 12.1178 11.9375 12.0599Z" fill="#EFF2F6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.92623 11.2687C9.91779 11.3025 9.91779 11.3234 9.88779 11.3228C9.85717 11.3222 9.84217 11.3109 9.84279 11.2797C9.84279 11.255 9.85904 11.2378 9.87748 11.2394C9.89873 11.2409 9.92904 11.2469 9.92623 11.2687Z" fill="#CCD4E3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.01 10.7825C8.025 10.7987 8.04219 10.8115 8.04875 10.8281C8.05437 10.8418 8.04562 10.8628 8.02625 10.865C8.00469 10.8668 7.98156 10.8662 7.97469 10.8393C7.96844 10.8131 7.99125 10.8037 8.01 10.7825Z" fill="#E0E5EE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.78949 11.0156C7.78105 10.9796 7.72543 11.0024 7.72168 10.9612C7.73355 10.9924 7.82637 10.9227 7.78949 11.0156Z" fill="#7085B1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.50559 9.65778C7.49871 9.61934 7.43965 9.64965 7.43652 9.60559C7.45309 9.63184 7.53684 9.56965 7.50559 9.65778Z" fill="#5C75A7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9711 9.34874C10.9771 9.32499 10.9961 9.28624 10.9886 9.27906C10.8868 9.18843 11.0227 9.17312 11.043 9.15468C11.0802 9.11874 11.0743 9.2703 11.1724 9.17437C11.1196 9.29155 11.049 9.32531 10.9677 9.34531L10.9711 9.34874Z" fill="#E7EAF2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.9594 9.25153C7.86003 9.24185 7.94847 9.11247 7.9269 9.03747C7.90003 8.9406 7.97065 8.94185 8.0394 8.94153C8.14596 8.94091 8.20628 8.98591 8.20628 9.09841C8.20597 9.20903 8.15472 9.25278 7.9594 9.25153Z" fill="#15A24A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7567 9.87587C11.7349 9.94275 11.6539 9.98462 11.6402 10.0671C11.6389 10.0781 11.6077 10.0759 11.6017 10.044C11.5767 9.89462 11.5755 9.89494 11.4652 9.97494C11.4564 9.9815 11.4411 9.98744 11.4327 9.98462C11.4164 9.97744 11.4099 9.96119 11.4196 9.94275C11.4561 9.8715 11.4905 9.79837 11.5314 9.72931C11.5589 9.68369 11.5924 9.72181 11.6192 9.73494C11.6783 9.764 11.7511 9.78056 11.7567 9.87587Z" fill="#13A248" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0356 10.119C11.9781 10.1421 11.9503 10.0987 11.9134 10.0831C11.8884 10.0724 11.865 10.0624 11.8481 10.0902C11.8297 10.1196 11.8481 10.1365 11.8734 10.1496C11.8928 10.1593 11.9134 10.1668 11.9278 10.1821C11.9531 10.2087 12.0325 10.2015 12.0003 10.2581C11.975 10.3031 11.9325 10.2506 11.9044 10.234C11.8559 10.2043 11.76 10.1881 11.7703 10.1365C11.7862 10.0537 11.8334 9.96368 11.8962 9.9068C11.9466 9.86118 12.0022 9.94743 12.0587 9.96899C12.0831 9.97805 12.1247 9.98618 12.1175 10.0246C12.1106 10.0643 12.0812 10.0412 12.0587 10.0359C12.0059 10.0237 11.9547 9.9343 11.9131 9.99618C11.8881 10.0331 11.9909 10.0484 12.0234 10.089C12.0284 10.0949 12.0297 10.1031 12.0356 10.119ZM12.7091 10.5609C12.7184 10.634 12.6887 10.6837 12.6122 10.6712C12.5328 10.6581 12.4725 10.6096 12.4587 10.5227C12.4575 10.514 12.4681 10.4962 12.4769 10.4937C12.4819 10.492 12.4872 10.4915 12.4925 10.4921C12.4978 10.4928 12.5028 10.4945 12.5073 10.4974C12.5118 10.5002 12.5156 10.5039 12.5185 10.5083C12.5214 10.5128 12.5233 10.5178 12.5241 10.5231C12.5341 10.5827 12.5653 10.6093 12.6281 10.6106C12.6403 10.5127 12.4637 10.4681 12.5653 10.3665C12.6162 10.3156 12.6784 10.3577 12.7228 10.3987C12.7637 10.4359 12.8137 10.4768 12.7391 10.5299C12.7284 10.5243 12.7141 10.5152 12.7062 10.5215C12.6903 10.5321 12.7059 10.5474 12.7091 10.5609Z" fill="#11A147" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5509 9.63023C10.5137 9.60929 10.5156 9.58929 10.5215 9.56742C10.5334 9.52085 10.5093 9.49648 10.4668 9.48335C10.4243 9.4696 10.3915 9.47148 10.38 9.5246C10.3759 9.54835 10.3634 9.56492 10.3375 9.55617C10.3075 9.54523 10.3225 9.52085 10.3281 9.50304C10.34 9.4621 10.3506 9.42085 10.3693 9.38179C10.3862 9.34273 10.3743 9.27117 10.4359 9.27585C10.5012 9.28054 10.5718 9.29617 10.6215 9.35023C10.6437 9.37398 10.6528 9.41492 10.6347 9.43273C10.5818 9.4871 10.5862 9.56398 10.5509 9.63023Z" fill="#0FA046" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.62207 9.23252C8.52113 9.27064 8.44145 9.24845 8.36363 9.25002C8.32832 9.25095 8.3252 9.22439 8.3252 9.19751C8.3277 9.16783 8.3277 9.13783 8.3277 9.10814C8.33145 8.94345 8.38988 8.90127 8.55176 8.9472C8.56988 8.9522 8.58707 8.95533 8.58832 8.97564C8.58832 9.00377 8.56801 9.00877 8.54301 9.00814C8.53957 9.00752 8.53551 9.00658 8.53113 9.0072C8.4827 9.01908 8.39582 8.96439 8.39426 9.03689C8.39238 9.09251 8.48707 9.05314 8.53551 9.07189C8.54238 9.0747 8.54988 9.08095 8.56051 9.08877C8.52988 9.14408 8.47895 9.11314 8.43957 9.1197C8.41613 9.12345 8.38551 9.11314 8.38207 9.15127C8.3777 9.19627 8.40895 9.19408 8.43957 9.1972C8.49176 9.20345 8.54832 9.18377 8.62207 9.23252Z" fill="#0B9F43" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.79291 9.25624C7.73853 9.24436 7.74603 9.21311 7.73666 9.19124C7.72041 9.15092 7.68822 9.15092 7.65291 9.15374C7.6126 9.15749 7.59072 9.17311 7.59416 9.2178C7.59666 9.23967 7.60541 9.27499 7.56478 9.26999C7.52978 9.26561 7.53978 9.23155 7.53791 9.20842C7.53478 9.17061 7.53103 9.13186 7.53478 9.09436C7.53978 9.05249 7.49978 8.98842 7.5601 8.97342C7.62228 8.95717 7.69416 8.94561 7.75885 8.97655C7.78697 8.98999 7.80916 9.0228 7.79885 9.04936C7.7751 9.11624 7.79385 9.18405 7.79291 9.25624Z" fill="#079D3F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.13281 9.14993C7.14281 9.06524 7.18 8.99649 7.27281 8.99961C7.36187 9.00243 7.40938 9.0643 7.40938 9.15336C7.40938 9.2418 7.36812 9.30274 7.27406 9.30211C7.17844 9.30149 7.14594 9.23211 7.13281 9.14993Z" fill="#15A24A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0819 10.7374C13.0732 10.8215 13.0316 10.8849 12.9357 10.8933C12.8472 10.9005 12.8004 10.8499 12.7935 10.7668C12.7857 10.6827 12.8682 10.6024 12.9522 10.603C13.026 10.6037 13.08 10.6571 13.0819 10.7374Z" fill="#11A147" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.328 9.75938C11.3986 9.81626 11.313 9.85376 11.3099 9.90157C11.2511 9.87438 11.1777 9.88532 11.1302 9.83376C11.0796 9.77876 11.0889 9.71657 11.1221 9.65594C11.1586 9.59188 11.2149 9.56438 11.2839 9.58876C11.3471 9.61126 11.3943 9.65376 11.3636 9.73376C11.3152 9.74251 11.2755 9.67876 11.2074 9.72157C11.2527 9.74251 11.3071 9.71344 11.328 9.75938Z" fill="#0C9F43" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.62819 9.11556C9.58694 9.164 9.55663 9.14525 9.52819 9.14587C9.50069 9.14587 9.47132 9.12306 9.43726 9.1515C9.46788 9.2015 9.54194 9.16931 9.57069 9.219C9.56663 9.22619 9.56476 9.23587 9.55944 9.24087C9.51819 9.27337 9.45226 9.19462 9.42663 9.25962C9.40319 9.31556 9.46476 9.29619 9.49132 9.30556C9.52132 9.31744 9.55851 9.31306 9.59476 9.35369C9.51069 9.39056 9.41038 9.37337 9.37569 9.33275C9.32101 9.26869 9.35944 9.15494 9.40101 9.09806C9.45007 9.03087 9.53976 9.0965 9.62819 9.11556Z" fill="#19A44D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1609 9.38749C10.0899 9.37999 10.0093 9.32999 9.98901 9.44686C9.98838 9.45061 9.9637 9.45593 9.9562 9.45093C9.94776 9.44624 9.94307 9.42874 9.94463 9.41874C9.96307 9.34467 9.97245 9.2653 10.0053 9.19874C10.0434 9.12374 10.1081 9.18718 10.1615 9.19436C10.2253 9.2028 10.2606 9.24155 10.2537 9.30874C10.2474 9.36655 10.2037 9.38218 10.1609 9.38749Z" fill="#0D9F44" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0061 9.56656C10.9992 9.66062 10.953 9.72562 10.8589 9.72843C10.7749 9.73156 10.7277 9.67249 10.7295 9.58874C10.732 9.49812 10.7761 9.43249 10.8739 9.42968C10.9636 9.42656 10.9983 9.48687 11.0061 9.56656Z" fill="#0FA046" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.89924 9.2271C8.88893 9.24928 8.88049 9.28085 8.85081 9.27366C8.79924 9.26147 8.82237 9.21178 8.80987 9.17928C8.77174 9.1421 8.78174 9.19335 8.76987 9.20272C8.76549 9.22585 8.77612 9.26116 8.74487 9.26585C8.70643 9.27053 8.71549 9.23366 8.71643 9.21178C8.71956 9.16616 8.72018 9.11835 8.73268 9.0746C8.74362 9.0371 8.70393 8.96803 8.77862 8.96741C8.84456 8.9671 8.83018 9.03428 8.83862 9.0746C8.84737 9.12085 8.85206 9.14053 8.88987 9.0946C8.92893 9.08428 8.93706 9.11272 8.94612 9.14022C8.95112 9.18022 8.93331 9.20803 8.89924 9.2271Z" fill="#0EA044" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2277 10.375C12.2509 10.375 12.2743 10.4056 12.2968 10.3793C12.314 10.3597 12.2987 10.3403 12.2834 10.3243C12.2721 10.3122 12.2602 10.3006 12.2496 10.2875C12.2177 10.249 12.1731 10.2122 12.2171 10.1565C12.2571 10.1056 12.3037 10.1181 12.3499 10.1503C12.3584 10.1665 12.3556 10.1822 12.3468 10.1968C12.3127 10.2503 12.3762 10.2828 12.3749 10.3284C12.3846 10.3712 12.3909 10.4143 12.3437 10.439C12.2849 10.4703 12.2396 10.4415 12.1965 10.404C12.1918 10.3793 12.2009 10.3672 12.2277 10.375Z" fill="#10A146" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3282 9.75928C11.3042 9.77616 11.2557 9.72147 11.2573 9.76272C11.2604 9.83584 11.2238 9.82928 11.1898 9.80772C11.1438 9.77928 11.1413 9.73053 11.1704 9.68553C11.1985 9.64147 11.2382 9.61584 11.2863 9.65178C11.3157 9.67334 11.3379 9.70584 11.3632 9.73366L11.3282 9.75928Z" fill="#ECF7F0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.91965 9.15313C8.91051 9.13383 8.9005 9.11496 8.88965 9.09657C8.90059 9.06875 8.91059 9.04063 8.92371 9.01375C8.93559 8.98844 8.95621 8.97438 8.98527 8.98375C9.01777 8.99469 9.01527 9.01969 9.0134 9.04594C9.00652 9.1225 9.00027 9.19875 8.99434 9.27532C8.94871 9.30438 8.93715 9.27782 8.92965 9.23844C8.92402 9.21 8.93871 9.17938 8.91965 9.15313Z" fill="#17A34C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3758 10.3281C12.3476 10.2993 12.3176 10.2721 12.2914 10.2415C12.2608 10.2052 12.2726 10.1831 12.3183 10.1771C12.3842 10.1918 12.4245 10.2237 12.4051 10.2993C12.3961 10.3087 12.3861 10.3187 12.3758 10.3281Z" fill="#D7EFE0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4052 10.2994C12.3749 10.26 12.3668 10.2038 12.3184 10.1772L12.3509 10.15C12.4034 10.1872 12.4993 10.2085 12.4052 10.2994Z" fill="#2BAB5B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.709 10.5609C12.7049 10.5584 12.6996 10.5572 12.6977 10.5537C12.6727 10.5075 12.5899 10.4659 12.6181 10.425C12.6534 10.3756 12.6902 10.434 12.7096 10.4762C12.7184 10.494 12.729 10.5115 12.739 10.53C12.7287 10.5403 12.7184 10.5506 12.709 10.5609Z" fill="#E6F5EC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2275 10.3749C12.2175 10.3846 12.2078 10.3946 12.1963 10.4043C12.1831 10.3949 12.165 10.3884 12.1566 10.3749C12.1406 10.3446 12.095 10.3043 12.1478 10.2806C12.1922 10.2593 12.1922 10.3159 12.2066 10.3434C12.2125 10.3549 12.22 10.3646 12.2275 10.3749Z" fill="#29AA59" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.92035 9.15308C8.96598 9.17089 8.94191 9.20901 8.94254 9.23745C8.94504 9.27651 8.95879 9.28964 8.99535 9.27589C8.9191 9.34964 8.92879 9.2512 8.89941 9.22933C8.90723 9.20339 8.91316 9.17808 8.92035 9.15308Z" fill="#DDF1E4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.76855 9.20304V9.10242C8.81168 9.11617 8.81293 9.14648 8.80855 9.1796C8.79543 9.18742 8.78168 9.19492 8.76855 9.20304Z" fill="#6FC690" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.98926 9.09993C7.99363 9.05431 7.97176 8.99493 8.04957 8.99993C8.10644 9.00337 8.14769 9.02618 8.15082 9.09118C8.15394 9.16118 8.11301 9.18962 8.04832 9.19431C7.96613 9.20087 7.99957 9.13618 7.98926 9.09993Z" fill="#F5FBF7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5932 9.78742C11.6257 9.80523 11.6754 9.80867 11.686 9.8568C11.6913 9.8818 11.6697 9.89836 11.6476 9.89117C11.6063 9.87867 11.5516 9.87805 11.546 9.81992C11.5444 9.80305 11.5626 9.7818 11.5932 9.78742Z" fill="#EFF8F3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5801 9.40303C10.5716 9.43553 10.5488 9.44678 10.5226 9.4371C10.4872 9.42428 10.4304 9.43835 10.4297 9.37647C10.4285 9.34428 10.4504 9.3346 10.4797 9.34428C10.5185 9.35678 10.5654 9.35522 10.5801 9.40303Z" fill="#DEF2E5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.66072 9.01843C7.69135 9.02218 7.73978 9.00593 7.73666 9.05812C7.73353 9.10375 7.68728 9.09656 7.65416 9.09718C7.6276 9.09812 7.58947 9.11187 7.58697 9.06406C7.5851 9.00718 7.62947 9.025 7.66072 9.01843Z" fill="#F3FAF5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.26545 9.06189C7.32201 9.06564 7.35014 9.09939 7.35232 9.15251C7.35545 9.20001 7.33201 9.24064 7.28326 9.24501C7.22701 9.25033 7.2017 9.20752 7.19857 9.15533C7.19545 9.1072 7.2142 9.0722 7.26545 9.06189Z" fill="#F3FAF6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0205 10.7483C13.0123 10.8002 12.9811 10.8368 12.9208 10.8368C12.8723 10.8362 12.8542 10.8008 12.8592 10.758C12.8639 10.6999 12.8986 10.6624 12.9576 10.6633C13.0042 10.6646 13.0223 10.698 13.0205 10.7483Z" fill="#F9FCFA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0874 9.23368C10.119 9.25306 10.1824 9.23306 10.1915 9.29556C10.1943 9.31931 10.1681 9.33149 10.1471 9.32681C10.1062 9.31774 10.049 9.33243 10.044 9.26649C10.0431 9.24618 10.0521 9.22431 10.0874 9.23368Z" fill="#E7F5EC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9438 9.57406C10.9379 9.62813 10.9113 9.66594 10.8567 9.66594C10.8029 9.66656 10.7838 9.62906 10.7863 9.57969C10.7907 9.52531 10.8217 9.48906 10.8745 9.48688C10.927 9.485 10.9438 9.52688 10.9438 9.57406Z" fill="#F5FBF7" />
        </svg>


    );
}