import React, { useState } from "react";

import "./auth.scss";
import { useHistory, useParams } from "react-router-dom";
import { post } from "../../utils/fetch";
import { ReactComponent as Logo } from "media/tutoria-logo.svg";
import { Button, FormControl, FormLabel, Input, IconButton, InputGroup, InputRightElement, Spinner, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function ResetPassword() {
  const { uid, token } = useParams();
  const [passwordReset, setPasswordReset] = useState(false);
  const history = useHistory();
  const [loadingReset, setLoadingReset] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState("");
  const { t } = useTranslation();
  const toast = useToast();

  async function resetPassword(e) {
    e.preventDefault()
    if (newPassword === confirmNewPassword) {
      try {
        setLoadingReset(true)
        await post(
          "/api/auth/password_reset/confirm/",
          {
            password: newPassword,
            password_confirm: confirmNewPassword,
            uid,
            token,
          },
          "POST"
        );
        setPasswordReset(true);
        setLoadingReset(false);
        setTimeout(() => {
          history.push("/login");
        }, 5000);
      } catch (e) {
        console.error(e);
        setLoadingReset(false);
        toast({
          description: t('FEEDBACKS.RESET_PASSWORD'),
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "bottom",
        });
      }
    } else {
      toast({
        description: t('FEEDBACKS.CONFIRM_PASSWORD_ERROR'),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      });
    }
  }

  const form = (
    <form className="auth-box" onSubmit={resetPassword}>
      <div className="img-container">
        <Logo />
      </div>
      <FormControl>
        <FormLabel for="new_password">{t('NEW_PASSWORD')}</FormLabel>
        <InputGroup>
          <Input required type={showNewPassword ? "text" : "password"} name="new_password" onChange={(e) => setNewPassword(e.currentTarget.value)} />
          <InputRightElement width='3rem'>
            <IconButton
              colorScheme='blue'
              aria-label={t('SHOW_PASSWORD')}
              icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => setShowNewPassword(!showNewPassword)}
              h='1.75rem'
              size='sm'
              focusBorderColor='orange.300'
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl mt={5}>
        <FormLabel for="confirm_password">{t('CONFIRM_NEW_PASSWORD')}</FormLabel>
        <InputGroup>
          <Input required type={showConfirmNewPassword ? "text" : "password"} name="confirm_new_password" onChange={(e) => setConfirmNewPassword(e.currentTarget.value)} />
          <InputRightElement width='3rem'>
            <IconButton
              colorScheme='blue'
              aria-label={t('SHOW_PASSWORD')}
              icon={showConfirmNewPassword ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              h='1.75rem'
              size='sm'
              focusBorderColor='orange.300'
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button
        className="main-btn"
        type="submit"
        isLoading={loadingReset}
        colorScheme="rgb(247,119,97)"
        spinner={<Spinner color="#F0F0F0" thickness="4px" speed="0.65s" />}
      >
        {t('CHANGE_PASSWORD')}
      </Button>
    </form>
  );

  const message = (
    <div className="auth-box">
      <p>
        {t('FEEDBACKS.PASSWORD_CHANGED')}
      </p>
    </div>
  );

  return <div className="auth">{passwordReset ? message : form}</div>;
}
