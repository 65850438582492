import {
  Accordion, Box, Button, Divider, Flex, Heading, Spinner, Tag, Text
} from "@chakra-ui/react";
import RichEditorComponent from "components/Editor/RichEditorComponent";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ContainerLayout from "../components/ContainerLayout";
import StudentTag from "../components/StudentTag";
import { SEND_FEEDBACK } from "../constants/Routes";
import { colorInverter } from "../utils/colorInverter";
import { post } from "../utils/fetch";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { ArrowBackIcon } from "@chakra-ui/icons";

let feedbacks = [];

const QuestionBox = ({ feedbackData, feedbackdata = [], index = 0 }) => {
  const { t } = useTranslation();
  const [currentQuestionNumber,] = useState(
    feedbackdata.question_number
  );
  const [currentQuestionDescription,] =
    useState(feedbackdata.title);
  const [currentMensage,] = useState(feedbackdata.feedback);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(currentMensage || ""))
  );
  let tagErrorList = feedbackdata?.tags.filter((tag) => tag.tag.type === "ERROR");
  const ErrorsList = tagErrorList?.map((tag) => (
    <Tag
      key={uuidv4()}
      backgroundColor={tag.tag.color}
      color={colorInverter(tag.tag.color)}
      borderRadius="100px"
    >
      {tag.tag.tag_name}
    </Tag>
  ));
  let tagCorrectList = feedbackdata?.tags.filter((tag) => tag.tag.type === "HIT");
  const CorrectList = tagCorrectList?.map((tag) => (
    <Tag
      key={uuidv4()}
      backgroundColor={tag.tag.color}
      color={colorInverter(tag.tag.color)}
      borderRadius="100px"
    >
      {tag.tag.tag_name}
    </Tag>
  ));

  return (
    <Box direction="row" marginLeft="2" marginTop="5">
      <Flex justifyContent="flex-start" alignItems="baseline">
        <Text fontSize="xl" color="gray.500">
          Q{currentQuestionNumber}.
        </Text>
        <Text fontSize="md">{currentQuestionDescription}</Text>
      </Flex>
      <Divider marginBottom="4" marginTop="2" />
      <Box mt="4">
        <Flex>
          <Text fontWeight="bold">{t('ERRORS')}:&nbsp;</Text>
          {ErrorsList.length > 0 ? (
            ErrorsList
          ) : (
            <Text>
              {feedbackData.student_name} {t('MADE_NO_MISTAKES')}
            </Text>
          )}
        </Flex>
        <Flex>
          <Text fontWeight="bold">{t('HITS')}:&nbsp;</Text>
          {CorrectList.length > 0 ? (
            CorrectList
          ) : (
            <Text>
              {feedbackData.student_name} {t('NO_HIT_FOR_THIS_QUESTION')}
            </Text>
          )}
        </Flex>
        <Box mt="4">
          <Text fontWeight="light" marginTop="14">
            {t('REVIEW_FEEDBACK')}:
          </Text>
        </Box>
        <Box mt="4">
          <RichEditorComponent
            editorState={editorState}
            setEditorState={setEditorState}
            onChangeCallback={(data) => {
              feedbacks[index].feedback = stateToHTML(
                data.getCurrentContent()
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const EditFeedback = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackData, setFeedbackData] = useState([]);
  const { workId, student_email, form_id, email } = history.location?.state;
  const { t } = useTranslation();

  useEffect(() => {
    post(`/api/courses/correction/feedback/edit/`, {
      work_id: workId,
      student_email: student_email,
      form_id: form_id,
    })
      .then((res) => {
        setFeedbackData(res.data[0]);
        for (let i = 0; i < res.data[0].feedback.length; i++) {
          feedbacks[i] = {
            feedback: res.data[0].feedback[i].feedback,
            question_id: res.data[0].feedback[i].question_id,
            response_id: res.data[0].feedback[i].response_id,
          };
        }
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const FeedbackAccordion = feedbackData?.feedback?.sort((a, b) => (a.question_number - b.question_number)).map((feedback, index) => {
    return <QuestionBox key={uuidv4()} feedbackData={feedbackData} feedbackdata={feedback} index={index} />;
  });

  return (
    <Flex spacing="4" paddingLeft="70px">
      {isLoading && (
        <>
          <Flex
            w="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        </>
      )}
      {!isLoading && (
        <ContainerLayout>
          <Flex w="100%" justify="flex-start" flexDirection="column" align="flex-start">
            <Button leftIcon={<ArrowBackIcon />} colorScheme='blue' variant='link' mb={2} onClick={() => {
              history.goBack()
            }}>
              {t('BACK')}
            </Button>
            <Heading
              className="unselectable"
              width="100%"
              size="lg"
              textAlign="left"
            >
              {t('STUDENT_FEEDBACK')}
            </Heading>
          </Flex>
          <Text
            className="unselectable"
            marginY="2"
            width="100%"
            textAlign="left"
          >
            {t('YOU_ARE_IN_THE_EVALUATION')}<b> {feedbackData?.form_name} </b>
          </Text>

          <Divider marginBottom="4" marginTop="2" />
          <Flex w="100%" justifyContent="flex-start">
            <Box center={"left"}>
              <StudentTag
                description={
                  feedbackData?.student_name || t('UNREGISTERED_STUDENT')
                }
                name={feedbackData?.student_name || t('UNREGISTERED_STUDENT')}
              />
            </Box>
          </Flex>

          <Flex direction="column" width="100%" justifyContent="flex-end">
            <Accordion defaultIndex={[0]} allowMultiple w="100%">
              {FeedbackAccordion}
            </Accordion>
          </Flex>
          <Flex w="100%" justifyContent="flex-end">
            <Button
              colorScheme="blue"
              mt="4"
              onClick={() => {
                post(`/api/courses/correction/feedback/edit/save/`, {
                  feedbacks: feedbacks,
                }).then(() => {
                  history.push({
                    pathname: SEND_FEEDBACK,
                    state: { workId: workId, email: email },
                  });
                  history.go();
                });
              }}
            >
              {t('SAVE_EDIT')}
            </Button>
          </Flex>
        </ContainerLayout>
      )}
    </Flex>
  );
};

export default EditFeedback;
