import React from "react";

import { Flex, Skeleton, Spacer, Wrap, WrapItem } from "@chakra-ui/react";

const ReviewPageSkeleton = () => {
  return (
    <Flex flexDirection="column" w="100%" pl="12" overflow="hidden">
      <Skeleton w="200px" h="10" />
      <Flex mt="4" mb="1" w="100%">
        <Skeleton w="100px" h="6" />
        <Spacer maxW="10px" />
        <Skeleton w="140px" h="6" />
      </Flex>
      <Skeleton mt="2" height="2px" borderRadius="100px" />
      <Flex h="50px" justifyContent="space-between" alignItems="center" mb="1">
        <Skeleton mt="6" w="340px" h="8" borderRadius="10px"></Skeleton>
      </Flex>
      <Wrap mt="12" minchildwidth="350px" spacing={6}>
        {Array.from({ length: 5 }, () => (
          <WrapItem>
            <Skeleton w="340px" h="200px" borderRadius="10px" />
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};

export default ReviewPageSkeleton;
