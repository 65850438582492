import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import Colors from "constants/Colors";
import { useCallback, useEffect, useState } from "react";

const ColorItem = ({ color, selected, onClick }) => {
  return (
    <Flex
      height="8"
      width="8"
      backgroundColor={color}
      borderRadius="md"
      color="white"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      userSelect="none"
      onClick={() => onClick?.(color)}
      role="group"
    >
      <CheckIcon
        height="3"
        color={`${selected ? "white" : "transparent"}`}
        _groupHover={{ color: selected ? "white" : "whiteAlpha.600" }}
      />
    </Flex>
  );
};

const ColorInput = ({ onChange, onClick, selected, color }) => {
  const [localColor, setLocalColor] = useState(color);

  const beforeChange = useCallback(
    (newColor) => {
      setLocalColor(newColor);
      const validHex = /^(?:[0-9a-fA-F]{3,4}){1,2}$/;
      if (validHex.test(newColor)) {
        onChange?.(newColor);
      }
    },
    [onChange]
  );

  return (
    <Box alignItems="center" display="inline-flex" width="28" borderRadius="md">
      <Flex
        height="8"
        width="8"
        backgroundColor={`#${color}`}
        borderRadius="md"
        borderRightRadius="none"
        color="white"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        userSelect="none"
        onClick={() => onClick?.(color)}
        role="group"
      >
        {!selected ? "#" : <CheckIcon height="3" />}
      </Flex>
      <Input
        maxHeight="8"
        boxSizing="border-box"
        maxWidth="20"
        borderLeftRadius="none"
        border={selected ? "2px" : "1px"}
        borderColor={`#${color}`}
        value={localColor}
        fontSize="md"
        padding="1"
        inputMode="text"
        maxLength={6}
        onChange={(e) => beforeChange(e.target.value)}
      />
    </Box>
  );
};

const ColorTrigger = ({ color, ...props }) => {
  return (
    <PopoverTrigger>
      <Button width="10">
        <Flex
          minHeight="10"
          minWidth="10"
          boxSizing="border-box"
          justifyContent="center"
          alignItems="center"
          shadow="2xl"
          backgroundColor="gray.50"
          padding={1.5}
          borderRadius="md"
          {...props}
        >
          <Box
            borderRadius="100%"
            backgroundColor={color}
            boxSizing="border-box"
            minHeight="7"
            minWidth="7"
            border="2px solid white"
            shadow="md"
          />
        </Flex>
      </Button>
    </PopoverTrigger>
  );
};

const ColorPickerPopover = ({
  color = Colors.list[0],
  onColorSelected = () => {},
  ...props
}) => {
  const [customColor, setCustomColor] = useState(
    Colors.default.replace("#", "")
  );

  const onSelectColor = useCallback(
    (newColor) => {
      onColorSelected(newColor);
    },
    [onColorSelected]
  );

  const onChangeCustomColor = useCallback(
    (newColor) => {
      setCustomColor(newColor);
      onSelectColor(`#${newColor}`);
    },
    [onSelectColor]
  );

  useEffect(() => {
    if (color && !Colors.list.includes(color) && color !== customColor) {
      setCustomColor(color.replace("#", ""));
    }
  }, [color, customColor]);

  return (
    <Popover>
      <PopoverTrigger>
        <Box width="10">
          <ColorTrigger color={color} {...props} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />

        <PopoverBody>
          <Flex flexWrap="wrap" gridGap="2">
            {Colors.list.map((_color) => (
              <ColorItem
                color={_color}
                selected={color === _color}
                onClick={onSelectColor}
                key={_color}
              />
            ))}
            <ColorInput
              color={customColor}
              onChange={onChangeCustomColor}
              onClick={onChangeCustomColor}
              selected={color === `#${customColor}`}
            />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPickerPopover;
