import { Box } from "@chakra-ui/react";
import MyEvaluationAnswer from "./MyEvaluationAnswer";
import MyEvaluationMarkers from "./MyEvaluationMarkers";
import MyEvaluationQuestion from "./MyEvaluationQuestion";

const MyEvaluationParagraphText = () => {
  return (
    <Box width="70%">
      <MyEvaluationQuestion />
      <MyEvaluationAnswer />
      <MyEvaluationMarkers />
    </Box>
  );
};

export default MyEvaluationParagraphText;
