import React from "react";

import {
  ButtonGroup,
  Button,
  Wrap,
  WrapItem,
  Divider,
  Flex
} from "@chakra-ui/react";

import { useHistory } from "react-router";

import { ClassMenu } from "../ClassMenu";
import StudentTag from "../StudentTag";
import ActivityCard from "../ActivityCard";

import { QUESTION } from "../../constants/Routes";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const UncompletedClassesList = (
  { classesWithUncompletedWorks, workStatus, classDeleteCallback, mail }
) => {
  let history = useHistory();
  const { setWorkId, setClassroomId } = useNavigationCorrection()
  return classesWithUncompletedWorks?.map((aluneClass) => (
    <Flex marginTop="6" key={aluneClass.id} direction="column" mb="6">
      <Flex>
        <ButtonGroup size="md" isAttached variant={aluneClass.name === "Avaliação tutoria" ? "solid" : "outline"}>
          <Button
            p="1"
            isDisabled={true}
            _disabled={{ cursor: "default" }}
          >
            <StudentTag
              avatar=""
              name={aluneClass.name}
              description={aluneClass.name}
              didShowBackground={false}
            />
          </Button>
          <ClassMenu course_id={aluneClass.id} isClassroomTest={aluneClass.name === "Avaliação tutoria"} callback={classDeleteCallback} />
        </ButtonGroup>
      </Flex>
      <Divider marginBottom="4" marginTop="2" />
      {!!workStatus[aluneClass.id] && (
        <>
          <Wrap minchildwidth="350px" spacing={6}>
            {aluneClass.works.map((work) => (
              <WrapItem key={work.id}>
                <ActivityCard
                  key={work.id}
                  numAnswers={work.answers}
                  isClassroomTest={aluneClass.name === "Avaliação tutoria"}
                  activityName={work.title}
                  activityDescription={work.description}
                  activityDate={work.due_date}
                  correctionPercentage={work.percentage_done}
                  onClick={() => {
                    setWorkId(work.id)
                    setClassroomId(aluneClass.id)
                    history.push({ pathname: QUESTION })
                  }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </Flex>
  ));
};

export default UncompletedClassesList;
