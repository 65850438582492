import { logOut } from "../../utils/user";

import { post } from "../../utils/fetch";

import { invalidateNavbarKeys } from "service/SideBarLocalStorage";

export default function UserLogout() {
  invalidateNavbarKeys();
  logOut();
  window.location.pathname = "/";
  return null;
}

export async function googleLogout(mail) {
  try {
    await post(`/api/auth/google/logout`, {
      email: mail,
    });
    invalidateNavbarKeys();
  } catch (err) {
    console.error("Error on Google Logout:", err);
  }
}
