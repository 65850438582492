import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const SlideFilter = (props) => {
  const { t } = useTranslation();
  const handleView = (clickContent) => {
    props.setViewOption(clickContent);
  };
  return (
    <Flex
      width="260px"
      height="32px"
      borderRadius="10px"
      backgroundColor="gray.100"
      justifyContent="space-around"
      alignItems="center"
      padding="1"
    >
      <Button
        backgroundColor={props.viewOption === "student" ? "" : "white"}
        borderRadius="8px"
        width="100%"
        height="26px"
        padding="0"
        marginRight="5px"
        onClick={() => handleView("question", 0)}
      >
        <Text fontSize="sm">{t('BY_QUESTION')}</Text>
      </Button>
      <Button
        backgroundColor={props.viewOption === "question" ? "" : "white"}
        borderRadius="8px"
        width="100%"
        height="26px"
        padding="0"
        onClick={() => handleView("student", 1)}
      >
        <Text fontSize="sm">{t('BY_STUDENT')}</Text>
      </Button>
    </Flex>
  );
};

export default SlideFilter;
