import React from "react";

import { Box, Text } from "@chakra-ui/react";

const VideoCard = ({ videoName = "", videoUrl = "" }) => {
  return (
    <Box w="436px" h="251px" p="3" borderWidth="1px" borderRadius="lg">
      <Text fontWeight="bold" my="10px">
        {videoName}
      </Text>
      <iframe
        title={videoName}
        style={{ borderRadius: "5px" }}
        allow="fullscreen;"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="80%"
        type="text/html"
        src={videoUrl}
      />
    </Box>
  );
};

export default VideoCard;
