
import "moment/locale/pt-br";
import "./styles.scss";
import { Box, Checkbox, CheckboxGroup, Flex, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, Heading, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BiEditAlt } from "react-icons/bi";
import { AiFillFilter } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import EvaluationTypes from "constants/EvaluationTypes";
import { AddIcon } from "@chakra-ui/icons";

const MyEvaluationTable = ({ tags, filters, filterTagsByType, title, onEdit, onDelete, onAdd, removeFeedback = false, tooltipText, titleWarning, descWarning }) => {
    const { t } = useTranslation();
    return (
        <div className="tableContainer">
            <Text className="title">{title}</Text>
            <table className="tableContainer__table">
                <thead className="tableContainer__table__header">
                    <th>Tag</th>
                    <th>
                        <Flex alignItems="center">
                            <Text mr='10px'>{t('TYPE')}</Text>
                            {filterTagsByType && <Popover placement='right'>
                                <PopoverTrigger>
                                    <IconButton
                                        size="sm"
                                        variant="ghost"
                                        aria-label={t('FILTER_BY_TYPE')}
                                        colorScheme={filters?.length > 0 ? "blue" : null}
                                        icon={<AiFillFilter />}
                                    />
                                </PopoverTrigger>
                                <PopoverContent w={180}>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>{t('FILTER_BY_TYPE')}</PopoverHeader>
                                    <PopoverBody>
                                        <Flex direction='column'>
                                            <CheckboxGroup onChange={(e) => filterTagsByType(e)}>
                                                <Checkbox value={EvaluationTypes.ERROR}>{t('ERROR')}</Checkbox>
                                                <Checkbox value={EvaluationTypes.HIT}>{t('HIT')}</Checkbox>
                                                <Checkbox value={EvaluationTypes.PARTIAL_HIT}>{t('PARTIAL_HIT')}</Checkbox>
                                            </CheckboxGroup>
                                        </Flex>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>}
                        </Flex>
                    </th>
                    {!removeFeedback && <th>Feedback</th>}
                    <th></th>
                </thead>
                <tbody className="tableContainer__table__body">
                    {tags?.map((tag) => {
                        return <tr className="tableContainer__table__line" key={tag.id}>
                            <td>
                                <Box
                                    textAlign="left"
                                    backgroundColor={tag.color}
                                    display="inline"
                                    paddingX="2"
                                    borderRadius="xl"
                                    color="white"
                                    onClick={() =>{
                                        if(onEdit) { 
                                            onEdit(tag)
                                        }
                                    }}
                                    cursor={onEdit && "pointer"}
                                >
                                    {tag.tag_name}
                                </Box>
                            </td>
                            <td>{t(tag.type)}</td>
                            {!removeFeedback && <td>{tag.explanation ? tag.explanation.slice(0, 30).trim() + (tag.explanation.length > 30 ? "..." : "") : "-"}</td>}
                            <td style={{ textAlign: "end" }}>
                                {onEdit && <Tooltip
                                    fontSize={14}
                                    padding={2}
                                    textColor={"#386BA6"}
                                    hasArrow
                                    label={t('EDIT_TAG')}
                                    placement="top-start"
                                    bg="white" >
                                    <IconButton
                                        variant="ghost"
                                        aria-label={t('EDIT_TAG')}
                                        icon={<BiEditAlt color="#386BA6" size={20} />}
                                        onClick={() => onEdit(tag)}
                                        mr={2}
                                    />
                                </Tooltip>}
                                {onAdd &&
                                    <Tooltip
                                        fontSize={14}
                                        padding={2}
                                        textColor={"#386BA6"}
                                        hasArrow
                                        label={tooltipText}
                                        placement="top-start"
                                        bg="white" >
                                        <IconButton
                                            colorScheme="blue"
                                            variant="ghost"
                                            aria-label={t('DELETE_TAG')}
                                            icon={<AddIcon size={20} />}
                                            onClick={() => onAdd(tag)}
                                        />
                                    </Tooltip>}
                                {onDelete && <Tooltip
                                    fontSize={14}
                                    padding={2}
                                    textColor={"#386BA6"}
                                    hasArrow
                                    label={t('DELETE_TAG')}
                                    placement="top-start"
                                    bg="white" ><IconButton
                                        variant="ghost"
                                        aria-label={t('DELETE_TAG')}
                                        icon={<AiOutlineDelete color="red" size={20} />}
                                        onClick={() => onDelete(tag)}
                                    />
                                </Tooltip>}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {tags?.length === 0 && <Flex flexDirection="column" align="center" margin={10}>
                <Text fontSize='xl' textAlign="center">{titleWarning}</Text>
                <Text textAlign="center">{descWarning}</Text>
            </Flex>}
        </div>
    );
};

export default MyEvaluationTable;
