import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Divider,
  Flex,
  Heading,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Wrap,
  WrapItem,
  Text,
  Box,
  Spacer,
  Button,
  AvatarGroup,
  Avatar
} from "@chakra-ui/react";

import { post } from "../utils/fetch";

import { EVALUATE, SEND_FEEDBACK } from "../constants/Routes";

import ContainerLayout from "../components/ContainerLayout";
import ActivityCard from "../components/ActivityCard";
import SearchBar from "../components/SearchBar";
import StudentTag from "../components/StudentTag";
import SlideFilter from "../components/SlideFilter";
import { useTranslation } from 'react-i18next';
import QuestionScreenSkeleton from "components/QuestionScreen/Skeleton/QuestionScreenSkeleton";
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from "utils/useQuery";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";
import FeedbackMessage from "components/FeedbackMessage";
import QuestionTypes from "constants/QuestionTypes";

const QuestionScreen = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState(true);
  const [correctedList, setCorrectedList] = useState([]);
  const [notCorrectedList, setNotCorrectedList] = useState([]);
  const [viewOption, setViewOption] = useState("question");
  const [responseData, setResponseData] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const query = useQuery();
  const { setListEvaluation, setResponseId, setQuestionId, setFilterBy, workId, setQuestionType } = useNavigationCorrection();

  const onChangeSearch = (query) => {
    setSearchQuery(query.target.value);
  };

  const handleGetActivities = () => {
    setLoadingActivities(true);
    post("api/courses/details/", {
      view: viewOption,
      work_id: workId || query.get("work_id"),
    }).then((res) => {
      let data = res.data;
      setResponseData(data);
      setNotCorrectedList(data.filter(item => item.answers.some(e => e.score === null)).map(item => {
        return {
          ...item,
          answers: item.answers.filter(e => e.score === null)
        }
      }))
      setCorrectedList(data.filter(question => question.answers.some(answer => answer.score !== null)).map(item => {
        return {
          ...item,
          answers: item.answers.filter(e => e.score !== null)
        }
      }))
      setListEvaluation(data)
      setLoadingState(false);
      setLoadingActivities(false);
    });
  }

  useEffect(() => {
    handleGetActivities()
    window.localStorage.setItem("viewOption", viewOption)
  }, [viewOption, workId]);

  const loadingTab = () => {
    return (<Flex w="100%" h="300px" alignItems="center" justifyContent="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>)
  }

  const getListAvatars = (listAvatars) => {
    return <AvatarGroup spacing="-0.50rem" size='sm' max={2}>
    {listAvatars.map((item, i) => {
      return <Avatar key={i} name={item.name} src={item.photo_url} />
    })}
  </AvatarGroup>
  }

  const sectionByQuestion = (question) => {
    return <Flex
      id={String(question.question_number)}
      marginTop="6"
      key={uuidv4()}
      direction="column"
    >
      <StudentTag
        avatar=""
        name={`Q ${question.question_number}`}
        description={`${question.title || t("QUESTION_WITHOUT_NAME")
          }`}
      />
      <Divider marginBottom="4" marginTop="2" />
      <Wrap minchildwidth="350px" spacing={6}>
          {
            question.question_type === QuestionTypes.MULTIPLE_CHOICE ?
            <WrapItem key={uuidv4()}>
            <ActivityCard
              numAnswers={2}
              activityName={t("MULTIPLE_CHOICE")}
              activityDescription={question.answers[0]?.description || ""}
              hasNotPercentage={true}
              avatarGroup={getListAvatars(question.answers)}
              onClick={() => {
                setQuestionId(question.question_id)
                setResponseId(question.answers[0].response_id)
                setQuestionType(question.question_type)
                history.push({
                  pathname: EVALUATE,
                  state: {
                    questionId: question.question_id,
                    responseId: question.answers[0].response_id,
                    responseData: responseData,
                    workId: workId,
                    viewOption: viewOption,
                  },
                });
              }}
            />
          </WrapItem> :
          question.answers.map(answer => {
            return <WrapItem key={uuidv4()}>
              <ActivityCard
                numAnswers={2}
                activityName={`${answer?.name || t('UNREGISTERED_STUDENT')}`}
                activityDescription={answer?.answer}
                hasNotPercentage={true}
                hasAvatar={true}
                avatarName={answer.name}
                avatarSrc={`https:${answer?.photo_url}`}
                onClick={() => {
                  setQuestionId(question.question_id)
                  setResponseId(answer.response_id)
                  setQuestionType(question.question_type)
                  history.push({
                    pathname: EVALUATE,
                    state: {
                      questionId: question.question_id,
                      responseId: answer.response_id,
                      responseData: responseData,
                      workId: workId,
                      viewOption: viewOption,
                    },
                  });
                }}
              />
            </WrapItem>
          })
          }
      </Wrap>
    </Flex>
  }

  const sectionByStudent = (student) => {
    return <Flex marginTop="6" key={uuidv4()} direction="column">
      <StudentTag
        avatar={
          student?.photo_url
            ? `https:${student?.photo_url}`
            : t('UNREGISTERED_STUDENT')
        }
        name={`${student.name || t('UNREGISTERED_STUDENT')}`}
        description={`${student.name || t('UNREGISTERED_STUDENT')}`}
      />
      <Divider marginBottom="4" marginTop="2" />
      <Wrap minchildwidth="350px" spacing={6}>
        {student.answers.map(answer => {
          return <WrapItem key={uuidv4()}>
            <ActivityCard
              numAnswers={2}
              activityName={`Q${answer.question_number}. ${answer.title}`}
              activityDescription={answer.answer}
              hasNotPercentage={true}
              onClick={() => {
                setQuestionId(answer.question_id)
                setResponseId(answer.response_id)
                setQuestionType(answer.question_type)
                history.push({
                  pathname: EVALUATE,
                  state: {
                    questionId: answer.question_id,
                    responseId: student.response_id,
                    responseData: responseData,
                    viewOption: viewOption,
                  },
                });
              }}
            />
          </WrapItem>
        })}
      </Wrap>
    </Flex>
  }

  const headerTab = () => {
    return <Flex
      w="100%"
      h="50px"
      justifyContent="space-between"
      alignItems="center"
      mb="6"
    >
      <Flex
        w="auto"
        alignItems="center"
        justifyContent="space-between"
      >
        <SlideFilter
          setViewOption={setViewOption}
          viewOption={viewOption}
        />
        <Spacer w="20px" />
        <SearchBar
          placeholder={
            viewOption === "question"
              ? t('FILTER_BY_QUESTION')
              : t('FILTER_BY_STUDENT')
          }
          onChangeText={onChangeSearch}
          value={searchQuery}
        />
      </Flex>
      <Button colorScheme="blue" onClick={() =>
        history.push({
          pathname: SEND_FEEDBACK,
          state: {
            workId: workId,
            formName: props.formName,
          },
        })
      }>{t('FINISH_CORRECTION')}</Button>
    </Flex>
  }

  const handleSearchBy = (listCorrection) => {
    return viewOption === "question" ? listCorrection.filter(item => item?.title?.toLowerCase().includes(searchQuery.toLowerCase())) : listCorrection.filter(item => item?.name?.toLowerCase().includes(searchQuery.toLowerCase()))
  }

  return (
    <ContainerLayout>
      {loadingState ? <QuestionScreenSkeleton /> :
          (<Box width="100%" paddingLeft="70px">
            <Heading width="100%" size="lg" textAlign="left">
              {t('OVERVIEW')}
            </Heading>
            <Tabs
              width="100%"
              mt={4}
            >
              <TabList>
                <Tab onClick={() => setFilterBy("to_correct")}>{t('ACTIVITIES_TO_CORRECT')}</Tab>
                <Tab onClick={() => setFilterBy("corrected")}>{t('CORRECTED_ACTIVITIES')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {headerTab()}
                  {loadingActivities ? loadingTab() : notCorrectedList.length ? handleSearchBy(notCorrectedList).map(item => {
                    return viewOption === "question" ? sectionByQuestion(item) : sectionByStudent(item)
                  }) : <FeedbackMessage title={t('FEEDBACK_MESSAGE_NOT_CORRECTED_ACTIVITIES_LIST.TITLE')} description={t('FEEDBACK_MESSAGE_NOT_CORRECTED_ACTIVITIES_LIST.DESCRIPTION')} />}
                </TabPanel>
                <TabPanel>
                  {headerTab()}
                  {loadingActivities ? loadingTab() : correctedList.length ? handleSearchBy(correctedList).map(item => {
                    return viewOption === "question" ? sectionByQuestion(item) : sectionByStudent(item)
                  }) : <FeedbackMessage title={t('FEEDBACK_MESSAGE_CORRECTED_ACTIVITIES_LIST.TITLE')} description={t('FEEDBACK_MESSAGE_CORRECTED_ACTIVITIES_LIST.DESCRIPTION')} />}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>)
      }
    </ContainerLayout>
  );
};

export default QuestionScreen;
