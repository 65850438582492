import React, { useState, useEffect } from "react";

import { fetchUserData } from "utils/user";

import {
  Box,
  Divider,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tooltip,
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  useDisclosure,
  AlertDialogFooter
} from "@chakra-ui/react";

import ContainerLayout from "../components/ContainerLayout";
import InfoSectionItem from "components/SettingsView/InfoSectionItem";
import AlgorithmItem from "components/SettingsView/AlgorithmItem";
import SearchBar from "components/SearchBar";

import { QuestionIcon } from "@chakra-ui/icons";

import {
  getThresholdIndex,
  postThresholdIndex,
  getPlagiumIndex,
  postPlagiumIndex,
  getProfile,
  postProfile,
  deleteAccount,
  putFeedbackButton,
} from "service/SettingsViewServices";

import { useTranslation } from 'react-i18next';
import ChangeLang from "components/ChangeLang";

const SettingsView = () => {
  const [searchValue, setSearchValue] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [plagiumIndex, setPlagiumIndex] = useState("");
  const [thresholdIndex, setThresholdIndex] = useState("");
  const [feedback, setFeedback] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef();

  const { t } = useTranslation();

  const usernameHandler = (newName) => {
    postProfile(newName, institution, setUsername, setInstitution);
    setUsername(newName);
  };

  const emailHandler = (newEmail) => {
    setEmail(newEmail);
  };

  const institutionHandler = (newInstitution) => {
    postProfile(username, newInstitution, setUsername, setInstitution);
    setInstitution(newInstitution);
  };

  const plagiumIndexHandler = (newplagiumIndex) => {
    postPlagiumIndex(newplagiumIndex, setPlagiumIndex);
  };

  const thresholdIndexHandler = (newthresholdIndex) => {
    postThresholdIndex(newthresholdIndex, setThresholdIndex);
  };
  const feedbackButtonHandler = (value) => {
    putFeedbackButton(value, setFeedback);
  };
  const getMail = async () => {
    const res = await fetchUserData();
    emailHandler(res.email);
    setFeedback(res.ai_feedback)
  };

  useEffect(() => {
    getMail();
    getThresholdIndex(setThresholdIndex);
    getPlagiumIndex(setPlagiumIndex);
    getProfile(setUsername, setInstitution);
  }, []);

  const accountInfo = [
    {
      itemName: t('NAME'),
      itemValue: username,
      itemCallback: usernameHandler,
      isEditingEnabled: true,
    },
    {
      itemName: t('EMAIL'),
      itemValue: email,
      itemCallback: emailHandler,
      isEditingEnabled: false,
    },
    {
      itemName: t('INSTITUTION'),
      itemValue: institution,
      itemCallback: institutionHandler,
      isEditingEnabled: true,
    },
  ];

  const algorithSensitivity = [
    {
      itemName: t('BOOKMARK_SUGGESTION'),
      itemValue: thresholdIndex,
      itemCallback: (val) => {
        thresholdIndexHandler(val);
      },
    },
    {
      itemName: t('PLAGIARISM_MECHANISM'),
      itemValue: plagiumIndex,
      itemCallback: (val) => {
        plagiumIndexHandler(val);
      },
    },
    {
      itemName: t('FEEDBACK_SUGGESTION_CONFIG'),
      itemValue: feedback,
      itemCallback: (val) => {
        feedbackButtonHandler(val)
      },
      showSlider: false
    },
  ];

  const handleOnSearchBarChange = (newValue) => {
    setSearchValue(newValue.target.value);
  };

  const deleteAccountUser = () => {
    deleteAccount((data) => {
      console.log("Conta excluída com sucesso!", data);
    });
    onClose();
    window.localStorage.clear();
    window.location.replace("/");
  };

  return (
    <ContainerLayout>
      <Box width="100%" paddingLeft="70px">
        <Heading width="100%" size="lg" textAlign="left">
          {t('SETTINGS')}
        </Heading>
        <Tabs width="100%" mt={4}>
          <TabList></TabList>
          <TabPanels>
            <SearchBar
              placeholder={t('SEARCH_HERE')}
              onChangeText={handleOnSearchBarChange}
              value={searchValue}
            />
            <Box>
              <Box pt="6">
                <Heading size="md" my="4">
                  {t('GENERAL_SETTINGS')}
                </Heading>
                <Divider />
                <Box pl="4">
                  <Text mt={5} fontWeight={"bold"}>{t('LANGUAGE')}</Text>
                  <ChangeLang />
                </Box>
              </Box>
              <Box pt="6">
                <Heading size="md" my="4">
                  {t('ACCOUNT_INFORMATION')}
                </Heading>
                <Divider />
                <Box pl="4">
                  {accountInfo?.map((item) => (
                    <>
                      {item.itemName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) && (
                          <InfoSectionItem
                            key={item.itemName + item.itemValue}
                            itemName={item.itemName}
                            itemValue={
                              item.itemValue === "" ? "-" : item.itemValue
                            }
                            itemCallback={item.itemCallback}
                            isEditingEnabled={item.isEditingEnabled}
                          />
                        )}
                    </>
                  ))}
                </Box>
              </Box>
              <Box>
                <Flex justifyContent={"flex-start"} alignItems="center">
                  <Heading size="md" my="4">
                    {t('ALGORITHM_SENSITIVITY')}
                  </Heading>
                  <Tooltip
                    hasArrow
                    placement="right"
                    label={t('ALGORITHM_SENSITIVITY_TOOLTIP')}
                    fontSize="sm"
                    bg="blue.50"
                    color="blue.400"
                    borderRadius="md"
                    shadow="none"
                    colorScheme="blue"
                    p="4"
                  >
                    <QuestionIcon ml="4" />
                  </Tooltip>
                </Flex>
                <Divider />
                <Box pl="4" my="2">
                  {plagiumIndex && feedback !== null &&
                    algorithSensitivity?.map((item) => (
                      <>
                        {item.itemName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) && (
                            <AlgorithmItem
                              key={item.itemName}
                              itemName={item.itemName}
                              itemValue={item.itemValue}
                              itemCallback={(val) => {
                                item.itemCallback(val);
                              }}
                              showSlider={item.showSlider}
                            />
                          )}
                        <Divider />
                      </>
                    ))}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box pt="6">
                <Heading size="md" my="4">
                  {t('ACCOUNT_SETTINGS')}
                </Heading>
                <Divider />
                <Box pl="4">
                  <Button mt={5} colorScheme='red' onClick={onOpen}>
                    {t('DELETE_ACCOUNT')}
                  </Button>
                  <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                          {t('DELETE_ACCOUNT')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                          {t('DELETE_ACCOUNT?')}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onClose}>
                            {t('CANCEL')}
                          </Button>
                          <Button colorScheme='red' onClick={deleteAccountUser} ml={3}>
                            {t('DELETE')}
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Box>
              </Box>
            </Box>
          </TabPanels>
        </Tabs>
      </Box>
    </ContainerLayout>
  );
};

export default SettingsView;
