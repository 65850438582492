import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { post } from "../utils/fetch";
import { useTranslation } from "react-i18next";

const generateColor = (percentage) => {
  if (percentage < 50) {
    return "red.400";
  } else if (percentage >= 50 && percentage < 70) {
    return "yellow.300";
  } else {
    return "green.400";
  }
};

const InfoBox = (props) => {
  return (
    <Flex borderRadius="lg" width="100%" marginY="2" padding="2" bg="white">
      <Box>
        <CircularProgress
          capIsRound
          value={props.percentage}
          color={generateColor(props.percentage)}
        >
          <CircularProgressLabel>{props.percentage}%</CircularProgressLabel>
        </CircularProgress>
      </Box>
      <Box marginX="4" direction="column">
        <Text fontSize="14px">{props.label}</Text>
        <Text fontSize="15px">
          <b>
            {props.correctedQuantity}/{props.totalQuantity}
          </b>{" "}
          {props.category}
        </Text>
      </Box>
    </Flex>
  );
};

const CorrectionProgress = (props) => {
  const [responseData, setResponseData] = useState({});
  const [percentageQuestions, setPercentageQuestions] = useState(0);
  const [percentageStudents, setPercentageStudents] = useState(0);
  const [percentageAnswers, setPercentageAnswers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const calculatePercent = (all_items, items_done) => {
    return all_items !== 0
      ? Math.trunc(
        (items_done / all_items) *
        100
      )
      : 0
  }

  useEffect(() => {
    post("api/courses/correction/statistics/", {
      course_id: props.id,
      course_work_id: props.activityId,
      response_id: props.responseId,
      form_id: props.formId,
      topn: props.topN,
    }).then((res) => {
      setResponseData(res.statistics);
      setPercentageQuestions(calculatePercent(res.statistics.all_questions, res.statistics.questions_done));
      setPercentageStudents(calculatePercent(res.statistics.all_students, res.statistics.students_done));
      setPercentageAnswers(calculatePercent(res.statistics.all_answers, res.statistics.answers_done));
      setIsLoading(false);
    });
  }, [props.activityId, props.formId, props.id, props.responseId, props.topN]);

  return (
    <Flex>
      {!isLoading && (
        <>
          <InfoBox
            percentage={percentageQuestions}
            label={t('YOU_ALREADY_CORRECTED')}
            category={t('QUESTIONS')}
            totalQuantity={responseData?.all_questions}
            correctedQuantity={responseData?.questions_done}
          />
          <InfoBox
            percentage={percentageAnswers}
            label={t('YOU_ALREADY_CORRECTED')}
            category={t('ANSWERS')}
            totalQuantity={responseData?.all_answers}
            correctedQuantity={responseData?.answers_done}
          />
          <InfoBox
            percentage={percentageStudents}
            label={t('YOU_ALREADY_CORRECTED')}
            category={t('STUDENTS').toLowerCase()}
            totalQuantity={responseData?.all_students}
            correctedQuantity={responseData?.students_done}
          />
        </>
      )}
    </Flex>
  );
};

export default CorrectionProgress;
