import { WarningIcon } from '@chakra-ui/icons';
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { ReactComponent as Logo } from "media/tutoria-logo.svg";
import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { post } from "../../utils/fetch";
import { getUserLogin, loginSuccess } from "../../utils/user";
import "./auth.scss";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import FooterGoogle from 'components/FooterGoogle';
import { useQuery } from 'utils/useQuery';


export default function Register() {
  const { t } = useTranslation();
  const defaultForm = { userType: "student" };
  const token = new URLSearchParams(window.location.search).get("token");
  const toast = useToast()
  const query = useQuery();

  if (token) {
    defaultForm.userType = "invite";
    defaultForm.token = token;
  }

  const [form, setForm] = useState(defaultForm);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [errors, setErrors] = useState([]);

  const changeForm = (e) => {
    const updatedForm = { ...form, [e.target.name]: e.target.value };
    setForm(updatedForm);
  };

  function validatePassword(password) {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  }

  const sendForm = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    // if (validatePassword(form.password)) {
    if (form.password && form.passwordConfirm && form.password !== form.passwordConfirm) {
      setIsLoading(false);
      if (!errors.includes(t('FEEDBACKS.CONFIRM_PASSWORD_ERROR'))) {
        setErrors(currentErrors => [...currentErrors, t('FEEDBACKS.CONFIRM_PASSWORD_ERROR')])
      }
      return;
    }
    // }
    // else {
    //   if (!errors.includes('Sua senha deve ter no mínimo 8 caracteres, pelo menos 1 letra e 1 número.')) {
    //     setErrors(currentErrors => [...currentErrors, 'Sua senha deve ter no mínimo 8 caracteres, pelo menos 1 letra e 1 número.'])
    //   }

    //   setIsLoading(false);
    //   return;
    // }
    try {
      await post("/api/auth/register/", {
        ...form,
        group: query.get("group") || "C"
      }).then(() => {
        toast({
          title: t('CREATE_ACCOUNT'),
          position: "bottom",
          description: t('FEEDBACKS.REDIRECT_TO_APP'),
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      });
    } catch (e) {
      setIsLoading(false)
      return setErrors(Object.values(e));
    }
    const { token } = await post("/api/auth/login/", {
      username: form.email,
      password: form.password,
    });

    const success = await loginSuccess(token);

    if (success && token && form.userType === "organization") {
      await post("/api/organization/create/", { name: form.organization });
    }

    if (success) {
      setLoggedIn(true);
    }
  };

  if (loggedIn || getUserLogin()) {
    return <Redirect to={"/login"} />;
  }

  const errorMessages = (erro) => {
    if (erro === 'Enter a valid email address.') {
      return t('FEEDBACKS.VALID_EMAIL')
    }
    return erro
  }

  return (
    <div className="auth">
      <form className={`auth-box ${form.userType}`} onSubmit={sendForm}>
        <div className="img-container">
          <Logo />
        </div>

        {form.userType ? (
          <>
            <div className="org-divider">
              <div className="user-info">
                <div className="form-section">
                  <label>{t('NAME')}</label>
                  <input
                    autoComplete="name"
                    name="name"
                    type="text"
                    required
                    value={form.name}
                    onChange={changeForm}
                    placeholder="Ex.: Maria da Silva"
                  />
                </div>

                <div className="form-section">
                  <label>{t('INSTITUTION')}</label>
                  <input
                    autoComplete="organization"
                    aria-invalid="false"
                    name="university"
                    placeholder="Ex.: ACME ltda"
                    type="text"
                    required
                    className=""
                    value={form.university}
                    onChange={changeForm}
                  />
                </div>

                <div className="form-section">
                  <label>{t('EMAIL')}</label>
                  <input
                    autoComplete="username email"
                    name="email"
                    placeholder="Ex.: tutoria@exemplo.com"
                    type="text"
                    aria-label="email input"
                    required
                    value={form.email}
                    onChange={changeForm}
                  />
                </div>

                <div className="form-section">
                  <label>{t('PASSWORD')}</label>
                  <input
                    autoComplete="new-password"
                    aria-invalid="false"
                    name="password"
                    placeholder="********"
                    type="password"
                    required
                    className=""
                    value={form.password}
                    onChange={changeForm}
                  />
                </div>

                <div className="form-section">
                  <label>{t('CONFIRM_PASSWORD')}</label>
                  <input
                    autoComplete="new-password"
                    aria-invalid="false"
                    name="passwordConfirm"
                    placeholder="********"
                    type="password"
                    required
                    className=""
                    value={form.passwordConfirm}
                    onChange={changeForm}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: 16
                  }}
                >
                  <input
                    id='agreeWithPrivacy'
                    type='checkbox'
                    name='agreeWithPrivacy'
                    required
                    value={form.agreeWithPrivacy}
                    onChange={changeForm}
                  />
                  <p style={{ fontSize: 14 }}>{t('READ_AND_AGREE')} <a href='/privacy'>{t('PRIVACY_POLICY')}</a> {t('OF_TUTORIA')}</p>
                </div>
              </div>
            </div>
            {errors.length > 0 ? (
              <div className="error-box">
                {errors.map((error) => (
                  <div key={uuidv4()}>
                    <WarningIcon marginBottom='5px' marginRight='5px' />
                    {errorMessages(error)}
                  </div>
                ))}
              </div>
            ) : null}

            <Button className="main-btn"
              type="submit"
              isLoading={isLoading}
              colorScheme="rgb(247,119,97)"
              spinner={<Spinner color='#F0F0F0' thickness="4px" speed="0.65s" />}
            >
              {t('CREATE_ACCOUNT')}
            </Button>
          </>
        ) : null}
        <div className="auth-switch">
          <Link to="/login">{t('ALREADY_HAVE_ACCOUNT')}</Link>
        </div>
      </form>
      <FooterGoogle position="absolute" />
    </div>
  );
}
