const { createContext, useState, useContext, useCallback } = require("react");

const WorkStatusContext = createContext({});

export const WorkStatusProvider = ({children}) => {

  const [workStatus, setWorkStatus] = useState({});

  const changeWorkStatus = useCallback((workId, value) => {
    setWorkStatus(previous => {
      const newWorkStatus = {...previous};
      newWorkStatus[workId] = value;
      return newWorkStatus;
    })
  }, []);

  return (
    <WorkStatusContext.Provider value={{workStatus, changeWorkStatus}} >
      {children}
    </WorkStatusContext.Provider>
  )
}

export const useWorkStatus = () => {
  const context = useContext(WorkStatusContext);

  if(!context) throw new Error('"useWorkStatus" must be used inside a WorkStatusProvider.');

  const { workStatus, changeWorkStatus } = context;

  return { workStatus, changeWorkStatus };
}