export const colorInverter = (bgHexColor) => {
  var darkenColor = function (color, percent) {
    var num = parseInt(color, 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1);
  };

  return parseInt(bgHexColor.replace("#", ""), 16) > 0xffffff / 1.6
    ? "#" + String(darkenColor(bgHexColor.replace("#", ""), -40))
    : "#" + String(darkenColor(bgHexColor.replace("#", ""), 80));
};
