export const filterUncompletedWorks = (classes, searchQuery) => {
  return classes
    ?.filter((course) =>
      course?.works?.some((work) => work?.percentage_done < 100)
    )
    .map((classes) => {
      return {
        ...classes,
        works: classes?.works.filter((work) => work?.percentage_done < 100),
      };
    })
    .filter((course) =>
      course.name
        .toUpperCase()
        .normalize("NFD")
        .replace(/[^a-zA-Zs0-9]/g, "")
        .includes(
          searchQuery
            .toUpperCase()
            .normalize("NFD")
            .replace(/[^a-zA-Zs0-9]/g, "")
        )
    );
};

export const filterCompletedWorks = (classes, searchQuery) => {
  return classes
    ?.filter((course) =>
      course?.works?.some((work) => work?.percentage_done === 100)
    )
    .map((classes) => {
      return {
        ...classes,
        works: classes?.works?.filter((work) => work?.percentage_done === 100),
      };
    })
    .filter((course) =>
      course.name
        .toUpperCase()
        .normalize("NFD")
        .replace(/[^a-zA-Zs0-9]/g, "")
        .includes(
          searchQuery
            .toUpperCase()
            .normalize("NFD")
            .replace(/[^a-zA-Zs0-9]/g, "")
        )
    );
};
