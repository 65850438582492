import { Flex, Heading, Text } from "@chakra-ui/react";

const FeedbackMessage = ({ title, description }) => {
    return (
        <Flex w="100%" p={120} justifyContent="center" alignItems="center" flexDirection="column">
            <Heading mb={4} as='h3' size='lg'>{title}</Heading>
            <Text fontSize='xl'>
                {description}
            </Text>
        </Flex>
    );
};

export default FeedbackMessage;
