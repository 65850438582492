import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Text,
  useToast
} from "@chakra-ui/react";

import OnboardVideoModal from "components/OnboardVideoModal/OnboardVideoModal";
import ReviewPageSkeleton from "components/ReviewPage/Skeleton/ReviewPageSkeleton";
import { homeVideo } from "constants/OnboardVideoUrl";
import { useWorkStatus } from "hooks/useWorkStatus";
import React, { useCallback, useEffect, useState } from "react";

import { getClassState, getCoursesDefault, getWorks, postGoogleWorks, postSaveForms, postSaveSubmissions } from "service/ReviewPageServices";
import { saveInfoToStorage } from "service/SideBarLocalStorage";
import {
  filterCompletedWorks,
  filterUncompletedWorks,
} from "utils/ReviewPage/Filters";

import ContainerLayout from "../components/ContainerLayout";
import SearchBar from "../components/SearchBar";

import { fetchUserData, storageSet } from "../utils/user";

import UncompletedClassesList from "components/ReviewPage/UncompletedClassesList";
import CompletedClassesList from "components/ReviewPage/CompletedClassesList";

import {
  LoadingClasses,
  LoadingSavingForms,
} from "components/ReviewPage/LoadingOnboarding";

import ClassSelectionModal from "components/ReviewPage/ClassSelectionModal";
import ImportClassrooms from "components/ReviewPage/ImportClassrooms";
import { useTranslation } from 'react-i18next';
import { useQuery } from "utils/useQuery";
import { useMemo } from "react";
import { useParamsMoodle } from "hooks/useParamsMoodle";

const States = {
  IsLoading: "IsLoading",
  IsSavingWorks: "IsSavingWorks",
  IsSavingForms: "IsSavingForms",
  IsSavingSubmissions: "IsSavingSubmissions",
  IsFirstLogin: "IsFirstLogin",
  Iddle: "Iddle",
};

const ReviewPage = () => {
  const [viewState, setViewState] = useState(States.Iddle);
  const toast = useToast()
  const [responseData, setResponseData] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [hasQueryFinished, setHasQueryFinished] = useState(false);
  const [isUserOnboardEnabled, setIsUserOnboardEnabled] = useState(
    localStorage.getItem("user-onboarding")
  );
  const [mail, setMail] = useState(null);
  const [allClasses, setAllClasses] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [showClassSelection, setShowClassSelection] = useState(false);
  const { t } = useTranslation();
  const query = useQuery();
  const { action, coursesId } = useParamsMoodle();
  const { changeWorkStatus, workStatus } = useWorkStatus();

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSubmissions = async (promises) => {
    try {
      await Promise.all(promises);
      getWorks(hasQueryFinishedHandler);
      setViewState(States.Iddle);
      window.location.href = window.location.href.replace("moodle_create", "moodle_view")
    } catch (e) {
      console.error(e);
      toast({
        description: t('FEEDBACKS.SUBMISSIONS_FAILED'),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      });
    }
  }

  const adjustPercentage = (completedSteps, totalSteps) => {
    return ((completedSteps * 100) / totalSteps).toFixed(0);
  };

  useEffect(() => {
    if (query.get("token")) {
      storageSet("tokenAuth", query.get("token"));
    }
    if (action === "moodle_create") {
      let ids = coursesId.split(",")
      if (ids) {
        setViewState(States.IsSavingSubmissions);
        let completedSteps = 0;
        let totalSteps = ids.length * 4;
        const promises = ids?.map(async (response) => {
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          await postSaveSubmissions(mail, response, () => { });
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          await postSaveForms(mail, response, () => { });
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
        });
        handleSubmissions(promises)
      }
    }
  }, [action, coursesId, query, mail])

  const classDeleteCallback = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    responseData.forEach(({ id }) => {
      if (workStatus[id]) return;
      const retryWorkStatus = async () => {
        getClassState(id, classStateHandler);
      };
      retryWorkStatus();
    });
  }, [responseData, changeWorkStatus]);

  useEffect(() => {
    handleUserOnboardingVideo();
  }, [isUserOnboardEnabled]);

  const handleUserOnboardingVideo = () => {
    if (isUserOnboardEnabled === null) {
      onOpen();
      localStorage.setItem("user-onboarding", true);
    }
  };

  const setUserCredentials = (email) => {
    setMail(email);
  };

  const classDataHandler = (workRes) => {
    if (workRes) {
      setAllClasses(workRes);
      setShowClassSelection(true);
    }
    setViewState(States.Iddle);
  };

  const handleGoogle = async (googleData) => {
    const res = await fetchUserData();
    setUserCredentials(res.email);
    if (googleData?.code) {
      setViewState(States.IsSavingWorks);
      try {
        postGoogleWorks(googleData.code, res.email, classDataHandler);
      } catch (error) {
        setViewState(States.Iddle);
        toast({
          description: t('FEEDBACKS.WORKS_FAILED'),
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const handleValidDefault = async (item) => {
    if (item.length) {
      try {
        await postSaveSubmissions(mail, item[0].id);
        window.location.reload();
      } catch (error) {
        toast({
          description: t('FEEDBACKS.SUBMISSIONS_FAILED'),
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "top",
        });
      }
    }
    else {
      toast({
        title: t('FEEDBACKS.CLASS_ALREADY_IMPORTED'),
        description: t('FEEDBACKS.CLASS_ALREADY_IMPORTED_DESCRIPTION'),
        status: 'warning',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleDefault = async () => {
    await getCoursesDefault(handleValidDefault);
  }

  useEffect(() => {
    setViewState(States.IsLoading);
    getWorks(hasQueryFinishedHandler);
  }, [hasQueryFinished]);

  const classStateHandler = (data, id) => {
    const { load_percentage, save_percentage } = data;
    if (load_percentage === 100 && save_percentage === 100) {
      changeWorkStatus(id, true);
    } else {
      changeWorkStatus(id, false);
    }
  };

  const hasQueryFinishedHandler = (response) => {
    if (response) {
      saveInfoToStorage("id", response?.data[0]?.id);
      if (action === "moodle_view_course" || query.get("action") === "moodle_view_course") {
        setResponseData(response.data.filter(item => item.id === coursesId || item.id === query.get("courses_id")));
      }
      else {
        setResponseData(response.data);
      }

    }
    setViewState(States.Iddle);
    setIsUserOnboardEnabled(localStorage.getItem("user-onboarding"));
  };

  const classesWithUncompletedWorks = useMemo(() =>
    filterUncompletedWorks(
      responseData,
      searchQuery
    ), [responseData]
  );

  const classesWithCompletedWorks = filterCompletedWorks(
    responseData,
    searchQuery
  );

  const ClassesListComplete = CompletedClassesList(
    classesWithCompletedWorks,
    classDeleteCallback,
    mail
  );

  switch (viewState) {
    case States.IsLoading:
      return (
        <ContainerLayout>
          <ReviewPageSkeleton />
        </ContainerLayout>
      );
    case States.IsSavingWorks:
      return <LoadingClasses feedback={t('LOADING_CLASSES')} />;
    case States.IsSavingSubmissions:
      return <LoadingSavingForms percentage={percentage} feedback={t('SAVING_YOUR_DATA')} />;
    case States.IsSavingForms:
      return <LoadingSavingForms percentage={percentage} feedback={t('SAVING_YOUR_DATA')} />;
    default:
      return (
        <>
          <ContainerLayout>
            {showClassSelection && (
              <ClassSelectionModal
                allClasses={allClasses}
                setShowClassSelection={setShowClassSelection}
                setViewState={setViewState}
                setHasQueryFinished={setHasQueryFinished}
                States={States}
                setPercentage={setPercentage}
                mail={mail}
              />
            )}

            <Flex flexDirection="column" w="100%" pl="12">
              <Flex w="100%">
                <Heading width="100%" size="lg" textAlign="left">
                  {t('MY_CLASSES')}
                </Heading>
                {
                  !action && <ImportClassrooms handleGoogle={handleGoogle} classDataHandler={classDataHandler} handleDefault={handleDefault} />
                }
              </Flex>

              <OnboardVideoModal
                isOpen={isOpen}
                onClose={onClose}
                videoUrl={homeVideo}
              />
              <Tabs width="100%" mt={4}>
                <TabList>
                  <Tab>{t('TO_CORRECT')}</Tab>
                  <Tab>{t('ALREADY_CORRECTED')}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Flex
                      h="50px"
                      justifyContent="space-between"
                      alignItems="center"
                      mb="1"
                      hidden={action === "moodle_view_course"}
                    >
                      <SearchBar
                        placeholder={t('FILTER_BY_CLASS')}
                        onChangeText={onChangeSearch}
                      />
                    </Flex>
                    <Flex flexDirection="column">
                      <UncompletedClassesList
                        classesWithUncompletedWorks={classesWithUncompletedWorks}
                        workStatus={workStatus}
                        classDeleteCallback={classDeleteCallback}
                        mail={mail}
                      />
                    </Flex>
                  </TabPanel>

                  <TabPanel>
                    <Flex
                      w="100%"
                      h="50px"
                      justifyContent="space-between"
                      alignItems="center"
                      mb="1"
                      hidden={action === "moodle_view_course"}
                    >
                      <SearchBar
                        placeholder={t('FILTER_BY_CLASS')}
                        onChangeText={onChangeSearch}
                        value={searchQuery}
                      />
                    </Flex>
                    <Flex flexDirection="column">{ClassesListComplete}</Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              {responseData?.length === 0 && (
                <Flex flexDirection="column" alignItems="center" w="100%" p="40">
                  <Text fontSize='3xl'>{t('FEEDBACKS.NO_IMPORTED_CLASSES')}</Text>
                  <Text fontSize='xl'>{t('FEEDBACKS.CLICK_BUTTON_IMPORT_CLASSES')}</Text>
                </Flex>
              )}
            </Flex>
          </ContainerLayout>
        </>
      );
  }


};

export default ReviewPage;
