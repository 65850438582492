import React, { useState } from "react";

import {
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text,
} from "@chakra-ui/react";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const VideoListBody = ({ videoList = [], hasCarousel = false }) => {
  const videoCount = videoList.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();
  const decreaseCurrentIndex = (currentIndex) => {
    setCurrentIndex(currentIndex - 1 >= 0 ? currentIndex - 1 : currentIndex);
  };

  const increaseCurrentIndex = (currentIndex) => {
    setCurrentIndex(
      currentIndex + 1 < videoCount ? currentIndex + 1 : currentIndex
    );
  };

  return (
    <Flex flexDir={"column"}>
      <iframe
        title={t('LEARN_TO_USE')}
        style={{ borderRadius: "5px" }}
        frameborder="0"
        width="100%"
        height="443"
        type="text/html"
        src={videoList[currentIndex]}
      />
      <Flex
        w="100%"
        justifyContent={"center"}
        alignItems={"center"}
        hidden={!hasCarousel}
      >
        <IconButton
          borderRadius={"xl"}
          icon={<ChevronLeftIcon />}
          onClick={() => {
            decreaseCurrentIndex(currentIndex);
          }}
        />
        <Flex
          pt="4"
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          w="200px"
          m="4"
        >
          <Slider
            defaultValue={0}
            min={0}
            max={videoCount}
            step={1}
            value={currentIndex + 1}
            isReadOnly={true}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
          </Slider>
          <Text fontSize={"sm"} mt="2" color={"blue.400"}>
            {t('TIP')} {currentIndex + 1}/{videoCount}
          </Text>
        </Flex>
        <IconButton
          borderRadius={"xl"}
          icon={<ChevronRightIcon />}
          onClick={() => {
            increaseCurrentIndex(currentIndex);
          }}
        />
      </Flex>
    </Flex>
  );
};

const OnboardVideoModal = ({
  isOpen = () => { },
  onClose = () => { },
  videoUrl = "",
  hasCarousel = false,
  videoList = [],
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('LEARN_TO_USE')}</ModalHeader>
        <ModalBody pb={6}>
          <VideoListBody
            videoList={hasCarousel ? videoList : [videoUrl]}
            hasCarousel={hasCarousel}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} size="sm">
            {t('CLOSE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OnboardVideoModal;
