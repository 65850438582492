import { get, post } from "utils/fetch";

export const postGoogleWorks = async (authorizationCode, email, callback) => {
  await post("api/auth/google/works/", {
    authorization_code: authorizationCode,
    email: email,
    force: false,
  })
    .then((data) => {
      callback(data);
    })
};

export const postSaveSubmissions = async (email, classId, callback) => {
  await post("api/courses/save/submissions/", {
    email: email,
    course_id: classId,
  })
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postSaveForms = async (email, classId, callback) => {
  await post("api/courses/save/forms/", {
    email: email,
    course_id: classId,
  })
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log("Error: ", err));
};

export const getClassState = async (id, callback) => {
  await get(`api/course/${id}/state`)
    .then((data) => {
      callback(data, id);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const getCoursesDefault = async (callback) => {
  await get(`api/courses/default/`)
    .then((data) => {
      callback(data.data);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
}

export const getMoodlePath = async (callback) => {
  await get(`api/moodle/path/`)
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
}

export const postMoodleWorks = async (email, password, institution_id, callback) => {
  await post("api/auth/moodle/works/", {
    login: email,
    password: password,
    institution_id: institution_id,
  })
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log("Error: ", err));
};

export const getWorks = async (callback) => {
  await get(`api/works/`)
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
}

export const postSaveClassrooms = async (courses, callback) => {
  post("api/google/save_courses", { courses })
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log("Error: ", err));
};