import React from "react";

import { Flex, Skeleton, SkeletonText, SkeletonCircle } from "@chakra-ui/react";

const SendFeedbackScreenSkeleton = () => {
  return (
    <Flex flexDirection="column" w="100%" overflow="hidden">
      {[1, 2, 3, 4, 5].map((item) => (
        <div key={item}>
          <Skeleton height="1px" borderRadius="100px" />
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              h="50px"
              justifyContent="flex-start"
              alignItems="center"
              mb="1"
            >
              <Skeleton ml="4" w="240px" h="6" borderRadius="10px"></Skeleton>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <SkeletonText w="100px" noOfLines={1} spacing="4" mx="1" />
              <SkeletonCircle size="5" mx="1" />
            </Flex>
          </Flex>
          <Skeleton height="1px" borderRadius="100px" />
        </div>
      ))}
    </Flex>
  );
};

export default SendFeedbackScreenSkeleton;
