import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { PRIVACY_POLICY } from "constants/Routes";
import brain from "media/brain.svg";
import computer from "media/computer.svg";
import doc from "media/doc.svg";
import aibox from "media/logo-aibox.png";
import rnp from "media/logo-rnp.png";
import mooodle_classroom from "media/moodle_classroom.svg";
import rocket from "media/rocket.svg";
import student from "media/student.png";

import Header from "../components/Header";
import ufrpe from "media/ufrpe-text.png";
import {
  FiArrowRight,
  FiFacebook,
  FiYoutube,
} from "react-icons/fi";
import BoardIcon from "../media/board.svg";
import CopyIcon from "../media/copy.svg";
import MouseIcon from "../media/mouse.svg";
import WireBrainIcon from "../media/wire-brain.svg";
import "components/styles.module.scss";

import { useTranslation } from 'react-i18next';

const Headline = ({ t }) => (
  <Flex p={"2"} bg={"gray.200"} w={"full"} justifyContent={"center"}>
    <Flex
      w={"90%"}
      maxW={"1520"}
      flexDir={{
        base: "column",
        md: "row",
      }}
    >
      <Flex
        flexDir={"column"}
        my={"auto"}
        w={{
          base: "full",
          md: "40%",
        }}
      >
        <Heading
          fontSize={{
            base: "30px",
            md: "50px",
          }}
          textAlign={"left"}
          color="#225794"
          my={"2"}
        >
          {t('LANDINGPAGE.SECTION1.TITLE')}
        </Heading>
        <Text
          fontSize={{
            base: "20px",
            md: "29px",
          }}
          textAlign={"left"}
          my={"2"}
        >
          {t('LANDINGPAGE.SECTION1.DESCRIPTION')}
        </Text>
        <Link href="/register">
          <Button
            my={"2"}
            colorScheme={"red"}
            size='lg'
            w={{
              base: "full",
              md: "xs",
            }}
          >
            {t('TRY_TUTORIA').toUpperCase()}
          </Button>
        </Link>
      </Flex>
      <Image
        my={"2"}
        src={computer}
        alt="Computer"
        w={{
          base: "full",
          md: "60%",
        }}
        maxW={"900px"}
      />
    </Flex>
  </Flex>
);

const Benefits = ({ t }) => (
  <Flex
    p={"2"}
    w={"full"}
    flexDir={"column"}
    alignItems={"center"}
    justifyContent={"space-evenly"}
    minH={"700px"}
    id="about"
  >
    <Heading
      color={"red"}
      fontSize={{
        base: "40px",
        md: "50px",
      }}
      textAlign={"center"}
    >
      {t('LANDINGPAGE.SECTION2.TITLE')}
    </Heading>
    <Flex
      alignItems={"center"}
      justifyContent={"space-around"}
      w={"85%"}
      flexDir={{
        base: "column",
        md: "row",
      }}
    >
      <Flex
        flexDir="column"
        maxW={"310px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        h={"340px"}
        my={"30px"}
      >
        <Image
          src={brain}
          maxW={{
            base: "170px",
            md: "195px",
          }}
          marginBottom={"50px"}
        />
        <Text fontSize={"23px"} textAlign={"center"}>
          {t('LANDINGPAGE.SECTION2.DESCRIPTION1')}
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        maxW={"310px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        h={"340px"}
        my={"30px"}
      >
        <Image
          src={doc}
          maxW={{
            base: "170px",
            md: "195px",
          }}
          marginBottom={"50px"}
        />
        <Text fontSize={"23px"} textAlign={"center"}>
          {t('LANDINGPAGE.SECTION2.DESCRIPTION2')}
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        maxW={"310px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        h={"340px"}
        my={"30px"}
      >
        <Image
          src={rocket}
          maxW={{
            base: "170px",
            md: "195px",
          }}
          marginBottom={"50px"}
        />
        <Text fontSize={"23px"} textAlign={"center"}>
          {t('LANDINGPAGE.SECTION2.DESCRIPTION3')}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

const EverybodyWins = ({ t }) => (
  <Flex w={"full"} bgColor={"red.100"} flexDir={"column"}>
    <Flex
      w={"full"}
      alignItems={"center"}
      justifyContent={"center"}
      my={"60px"}
    >
      <Heading fontSize={"50px"} color="#225794" textAlign={"center"}>
        {t('LANDINGPAGE.SECTION3.TITLE')}
      </Heading>
    </Flex>
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      flexDir={{
        base: "column",
        md: "row",
      }}
    >
      <WinnerCard
        title={t("PROFESSORS")}
        text={t('LANDINGPAGE.SECTION3.DESCRIPTION1')}
      >
        <Flex
          h={"100%"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"space-around"}
          maxW={"full"}
          minH={"160px"}
        >
          <Flex
            minH={"100%"}
            width={"full"}
            alignItems={"stretch"}
            justifyContent={"space-around"}
            maxW={"full"}
          >
            <Flex
              flexDir={"column"}
              maxW={"45%"}
              justifyContent={"space-between"}
            >
              <Text
                bgColor={"#225794"}
                borderRadius={"md"}
                p={"5px 30px"}
                fontSize={"20px"}
                color={"#FFF"}
                w={"min-content"}
              >
                {t('ANSWER')}
              </Text>
              <Text fontSize={"23px"} fontWeight={"bold"} width={"full"}>
                <Box as={"span"} textDecor={"underline"}>
                  {t('LANDINGPAGE.SECTION3.EXEMPLE1_PART1')}
                </Box>{" "}
                {t('LANDINGPAGE.SECTION3.EXEMPLE1_PART2')}
              </Text>
            </Flex>
            <Center mx={"2"}>
              <FiArrowRight size={"20px"} />
            </Center>
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
              maxW={"45%"}
            >
              <Text
                textAlign={"center"}
                bgColor={"gray.400"}
                borderRadius={"md"}
                p={"2"}
                fontSize={"20px"}
                color={"#FFF"}
              >
                {t('LANDINGPAGE.SECTION3.AUTO_CORRECTION')}
              </Text>
              <Text textAlign={"center"} fontSize={"16px"} fontWeight={"bold"}>
                {t('LANDINGPAGE.SECTION3.ERROR_MARKER_SUGESTION')}:
              </Text>
              <Flex bgColor={"#FF0000"} borderRadius={"md"} p={"1"}>
                <Text textAlign={"center"} fontWeight={"bold"} color={"#FFF"}>
                  {t('LANDINGPAGE.SECTION3.SUGESTION_EXEMPLE')}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </WinnerCard>
      <WinnerCard
        title={t("STUDENTS")}
        text={t("LANDINGPAGE.SECTION3.DESCRIPTION2")}
      >
        <Flex
          h={"100%"}
          position={"relative"}
          alignItems={"center"}
          justifyContent={"center"}
          minH={"160px"}
        >
          <Flex flexDir={"column"} alignItems={"center"} mr={"3"}>
            <Avatar src={student} size={"lg"} />
            <Text fontSize={"12px"} textAlign={"center"}>
              {t('PROFESSOR')} João Neves
            </Text>
          </Flex>
          <Flex flexDir={"column"}>
            <Text fontWeight={"bold"}>{t('LANDINGPAGE.SECTION3.SUBJECT')} I</Text>
            <Text fontWeight={"bold"} color={"red"}>
              {t('GRADE')}: 8,5
            </Text>
            <Text>
              {t('LANDINGPAGE.SECTION3.FEEDBACK_EXEMPLE')} <br />
              {t('LANDINGPAGE.SECTION3.ERRORS_EXEMPLE')} [...]
            </Text>
            <Button
              pos={"absolute"}
              top={"0"}
              right={"1"}
              colorScheme={"blue"}
              size={"sm"}
            >
              {t("KNOW_MORE")}
            </Button>
          </Flex>
        </Flex>
      </WinnerCard>
    </Flex>
  </Flex>
);

const WinnerCard = ({ title, text, children, t }) => (
  <Flex
    flexDir={"column"}
    w={{
      base: "90%",
      md: "45%",
    }}
    minW={"350px"}
    maxW={{
      base: "max",
      md: "2xl",
    }}
    m={{
      base: "10px 0",
      md: "10",
    }}
  >
    <Flex
      w={"full"}
      alignItems={"center"}
      justifyContent={"center"}
      bgColor={"red.300"}
      borderTopRadius={"xl"}
    >
      <Heading fontSize={"36px"} color={"#FFF"} textAlign={"center"} m={"3"}>
        {title}
      </Heading>
    </Flex>
    <Flex
      w={"full"}
      alignItems={"center"}
      justifyContent={"center"}
      bgColor={"#FFF"}
      borderBottomRadius={"xl"}
      p={"8"}
      flexDir={"column"}
    >
      <Box mb={"4"}>
        <Text fontSize={"26px"} textAlign={"left"}>
          {text}
        </Text>
      </Box>
      <Flex p={"0"} h={"full"}>
        {children}
      </Flex>
    </Flex>
  </Flex>
);

const Platforms = ({ t }) => (
  <Flex w={"full"} justifyContent={"center"} my={"10"} id="integrations">
    <Flex
      flexDir={"column"}
      width={"85%"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Heading
        fontSize={{
          base: "40px",
          md: "50px",
        }}
        color={"red"}
        fontWeight={"bold"}
      >
        {t('LANDINGPAGE.SECTION4.TITLE')}
      </Heading>
      <Text
        fontSize={{
          base: "20px",
          md: "29px",
        }}
        w={"90%"}
      >
        {t('LANDINGPAGE.SECTION4.DESCRIPTION')}
      </Text>
      <Image
        my={"10"}
        src={mooodle_classroom}
        w={{
          base: "90%",
          md: "70%",
        }}
      />
    </Flex>
  </Flex>
);

const OnlyWeOffer = ({ t }) => (
  <Flex width={"full"} bgColor={"gray.200"} py={"10"} id="highlights">
    <Flex flexDir={"column"} alignItems={"center"} width={"full"}>
      <Heading
        fontSize={"50px"}
        fontWeight={"bold"}
        color={"#225794"}
        p={"6"}
        textAlign={"center"}
      >
        {t('LANDINGPAGE.SECTION5.TITLE')}:
      </Heading>
      <Text fontSize={"1.4em"} p={"6"}>
        {t('LANDINGPAGE.SECTION5.DESCRIPTION')}
      </Text>
      <Flex
        alignItems={"stretch"}
        justifyContent={{
          base: "space-around",
          xl: "space-between",
        }}
        width={"80%"}
        flexWrap={{
          base: "wrap",
          xl: "unset",
        }}
      >
        <FeatureCard
          icon={MouseIcon}
          title={t('LANDINGPAGE.SECTION5.CARD1.TITLE')}
          text={t('LANDINGPAGE.SECTION5.CARD1.DESCRIPTION')}
        />
        <FeatureCard
          icon={WireBrainIcon}
          title={t('LANDINGPAGE.SECTION5.CARD2.TITLE')}
          text={t('LANDINGPAGE.SECTION5.CARD2.DESCRIPTION')}
        />
        <FeatureCard
          icon={BoardIcon}
          title={t('LANDINGPAGE.SECTION5.CARD3.TITLE')}
          text={t('LANDINGPAGE.SECTION5.CARD3.DESCRIPTION')}
        />
        <FeatureCard
          icon={CopyIcon}
          title={t('LANDINGPAGE.SECTION5.CARD4.TITLE')}
          text={t('LANDINGPAGE.SECTION5.CARD4.DESCRIPTION')}
        />
      </Flex>
    </Flex>
  </Flex>
);

const FeatureCard = ({ icon, title, text, t }) => (
  <Flex
    flexDir={"column"}
    bgColor={"#FFF"}
    borderRadius={"3xl"}
    w={"325px"}
    p={"30px"}
    position={"relative"}
    m={"2"}
  >
    <Center
      width={"110px"}
      height={"110px"}
      borderRadius={"50%"}
      shadow={"lg"}
      borderWidth={"1px"}
      borderColor={"blackAlpha.200"}
      mb={"67px"}
    >
      <Image src={icon} w={"52px"} maxH={"52px"} />
    </Center>
    <Heading fontSize={"25px"} color={"#225794"} minH={"50px"}>
      {title}
    </Heading>
    <Text fontSize={"21px"} mt={"32px"}>
      {text}
    </Text>
  </Flex>
);

const Partners = ({ t }) => (
  <Flex width={"full"} bgColor={"red.100"} py={"10"} justifyContent={"center"}>
    <Flex width={"80%"} flexDir={"column"} alignItems={"stretch"}>
      <Heading
        textAlign={"center"}
        color={"#225794"}
        fontSize={"50px"}
        my={"10"}
      >
        {t('PARTNERS')}
      </Heading>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Link
          target={"_blank"}
          referrerPolicy={"no-referrer"}
          href={"https://aiboxlab.org/"}
        >
          <Image my={"4"} mx={"12"} maxW={"250px"} maxH={"80px"} src={aibox} />
        </Link>
        <Link
          target={"_blank"}
          referrerPolicy={"no-referrer"}
          href={"http://www.ufrpe.br/"}
        >
          <Image my={"4"} mx={"12"} maxW={"250px"} maxH={"80px"} src={ufrpe} />
        </Link>
        <Link
          target={"_blank"}
          referrerPolicy={"no-referrer"}
          href={"https://www.rnp.br/"}
        >
          <Image my={"4"} mx={"12"} maxW={"250px"} maxH={"80px"} src={rnp} />
        </Link>
      </Flex>
    </Flex>
  </Flex>
);

const Footer = ({ t }) => (
  <Flex
    width={"full"}
    bgColor={"#225794"}
    py={"10"}
    justifyContent={"center"}
    alignItems={"center"}
    flexDir={"column"}
    color={"#FFF"}
  >
    <Flex
      mb={"8"}
      alignItems={"center"}
      maxW={"1000px"}
      width={"full"}
      justifyContent={"space-between"}
      px={"10"}
      flexDir={{
        base: "column",
        sm: "row",
      }}
      textAlign={{
        base: "center",
        sm: "left",
      }}
    >
      <Flex my={"4"} fontSize={"17px"} flexDir={"column"} h={"85px"}>
        <Text fontWeight={"bold"} mb={"2"}>
          {t('HELP').toUpperCase()}
        </Text>
        <Link
          onClick={() => {
            window.open(
              "mailto:tutoria.aibox@gmail.com?subject=Contato&body=Olá%20,%20gostaria%20de%20tirar%20dúvidas."
            );
          }}
        >
          {t('CONTACT')}
        </Link>
      </Flex>
      <Flex>
        <Flex my={"4"} fontSize={"17px"} flexDir={"column"}>
          <Text fontWeight={"bold"} mb={"2"}>
            {t('LEGAL')}
          </Text>
          <Link href={PRIVACY_POLICY}>{t('PRIVACY_POLICY')}</Link>
        </Flex>
      </Flex>
      <Flex
        my={"4"}
        fontSize={"17px"}
        flexDir={"column"}
        alignItems={"stretch"}
      >
        <Text fontWeight={"bold"} mb={"2"}>
          {t('SOCIAL_MEDIA')}
        </Text>
        <Flex justifyContent={"space-around"}>
          <Link
            href="https://www.facebook.com/aiboxlab/"
            aria-label={t('FACEBOOK')}
          >
            <FiFacebook size={"27px"} />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCZd6Oi-qCFjlVO4a1hfhK0w"
            aria-label={t('YOUTUBE')}
          >
            <FiYoutube size={"27px"} />
          </Link>
        </Flex>
      </Flex>
    </Flex>
    <Text
      alignSelf={{
        base: "flex-start",
        sm: "center",
      }}
      px={"10"}
      textAlign={{ base: "center", sm: "left" }}
      maxW={"1000px"}
      width={"full"}
    >
      Copyright © Tutoria - {t('ALL_RIGHTS')}
    </Text>
  </Flex>
);

export const Home = () => {
  const { t } = useTranslation();
  return (
    <Flex
      w={"full"}
      alignItems={"center"}
      flexDir={"column"}
      position={"sticky"}
      top={"0"}
    >
      <Header />
      <Headline t={t} />
      <Benefits t={t} />
      <EverybodyWins t={t} />
      <Platforms t={t} />
      <OnlyWeOffer t={t} />
      <Partners t={t} />
      <Footer t={t} />
    </Flex>
  );
};
