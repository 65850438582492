import React from "react";
import { Button, Menu, MenuButton, MenuItem, MenuList, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BrazilIcon } from "media/BrazilIcon";
import { USAIcon } from "media/USAIcon";
import { ChevronDownIcon } from "@chakra-ui/icons";

const ChangeLang = () => {
    const { i18n, t } = useTranslation();

    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("user-lang", lang);
    }

    return (
        <Menu>
            <MenuButton mr={2} as={Button} rightIcon={<ChevronDownIcon />}>
                {localStorage.getItem("user-lang") === 'en' ? <Flex><USAIcon /> <Text ml={2}>{t("ENGLISH")}</Text></Flex> : <Flex><BrazilIcon /> <Text ml={2}>{t("PORTUGUESE")}</Text></Flex>}
            </MenuButton>
            <MenuList>
                <MenuItem onClick={
                    () => handleClick('pt')
                }><BrazilIcon /> <Text ml={2}>{t("PORTUGUESE")}</Text></MenuItem>
                <MenuItem onClick={
                    () => handleClick('en')
                }><USAIcon /> <Text ml={2}>{t("ENGLISH")}</Text></MenuItem>
            </MenuList>
        </Menu>
    );
};

export default ChangeLang;