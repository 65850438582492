import { get, post } from "utils/fetch";

export const getThresholdIndex = async (callback) => {
  get("/api/profile/ai/")
    .then((data) => {
      callback(data.ai_threshold);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postThresholdIndex = async (value, callback) => {
  post("/api/profile/ai/", {
    ai_threshold: Number(value),
  })
    .then((data) => {
      callback(data.plagium_threshold);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const putFeedbackButton = async (value, callback) => {
  post("/api/profile/feedback/", {
    ai_feedback: value,
  }, "PUT")
    .then((data) => {
      callback(data.ai_feedback);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const getPlagiumIndex = async (callback) => {
  get("/api/profile/plagium/")
    .then((data) => {
      callback(data.plagium_threshold);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postPlagiumIndex = async (value, callback) => {
  post("/api/profile/plagium/", {
    plagium_threshold: value,
  })
    .then((data) => {
      callback(data.plagium_threshold);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const getProfile = async (callbackName, callbackInstitution) => {
  get("/api/profile/")
    .then((data) => {
      callbackName(data.name);
      callbackInstitution(data.university);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postProfile = async (
  name,
  institution,
  callbackName,
  callbackInstitution
) => {
  post("/api/profile/", {
    name: name,
    university: institution,
  })
    .then((data) => {
      callbackName(data.name);
      callbackInstitution(data.university);
    })
    .catch((err) => {
      callbackName(name);
      callbackInstitution(institution);
      console.log("Error: ", err);
    });
};

export const deleteAccount = async (callback) => {
  get("/api/auth/me/", "json", "DELETE")
    .then((data) => {
      callback(data);
    })
    .catch((err) => console.log("Error: ", err));
};
