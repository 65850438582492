const Colors = {
  list: [
    "#FF6900",
    "#FCB900",
    "#78DCB5",
    "#00D084",
    "#8ED1FC",
    "#0693E3",
    "#ABB8C3",
    "#EB144C",
    "#F78DA7",
    "#9900EF",
  ],
  default: "#878787",
};

export default Colors;
