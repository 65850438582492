import { Flex, Text, Popover, Alert, PopoverTrigger, AlertIcon, PopoverContent, PopoverArrow, PopoverHeader, PopoverBody, VStack, Button, Avatar } from "@chakra-ui/react";
import { useContext } from "react";
import { EvaluationContext } from "./MyEvaluationContext";
import StudentTag from "../StudentTag";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import QuestionTypes from "constants/QuestionTypes";

const MyEvaluationQuestion = () => {
  const { t } = useTranslation();
  const {
    studentProfilePhoto,
    studentName,
    evaluationQuestionNumber,
    evaluationQuestionTitle,
    possibleCopies,
    questionType
  } = useContext(EvaluationContext);

  const possibleCopiesButton = possibleCopies?.map((item, index) => {
    if (item.student !== "" && item.student !== null) {
      return (
        <Button
          key={uuidv4()}
          leftIcon={
            <Avatar
              src={item.student_photo}
              size="xs"
              name={item.student}
              ml={-1}
              mr={2}
            />
          }
          width={"300px"}
          variant="ghost"
        >
          {item.student}
        </Button>
      );
    }
  });
  return (
    <Flex direction="column" width="100%" justifyContent="flex-end">
      <Flex alignItems="center">
        {questionType === QuestionTypes.PARAGRAPH_TEXT && <StudentTag
          avatar={studentProfilePhoto || ""}
          name={studentName}
          description={studentName}
          className="unselectable"
        />}
        {(possibleCopies?.length > 0) && (possibleCopies.every(t => t.student)) && (
          <Popover placement={"right"}>
            <Alert
              marginY={1}
              status="error"
              backgroundColor={"#ffffff"}
            >
              <PopoverTrigger>
                <Button size="sm" variant="ghost"><AlertIcon />{t('POSSIBLE_PLAGIARISM')}</Button>
              </PopoverTrigger>
            </Alert>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                {t('POSSIBLE_PLAGIARISM_DESCRIPTION')}:
              </PopoverHeader>
              <PopoverBody>
                <VStack direction="row" spacing={1}>
                  {possibleCopiesButton}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
      <Text
        className="unselectable t-evaluation-text"
        fontSize="2xl"
        marginTop="5"
      >
        {evaluationQuestionNumber}. {evaluationQuestionTitle}
      </Text>
    </Flex>
  );
};

export default MyEvaluationQuestion;
