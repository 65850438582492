import React from "react";

import { useToast } from "@chakra-ui/react";

import { ClassSelection } from "../ClassSelection";

import { googleLogout } from "../auth/Logout";
import { postSaveForms, postSaveSubmissions } from "service/ReviewPageServices";
import { useTranslation } from "react-i18next";

const ClassSelectionModal = ({
  allClasses,
  setShowClassSelection,
  setViewState,
  setHasQueryFinished,
  States,
  setPercentage,
  mail
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const adjustPercentage = (completedSteps, totalSteps) => {
    return ((completedSteps * 100) / totalSteps).toFixed(0);
  };

  return (
    <ClassSelection
      classes={allClasses}
      callback={async (selectedClasses) => {
        setShowClassSelection(false);
        setViewState(States.IsSavingSubmissions);
        let completedSteps = 0;
        let totalSteps = selectedClasses.length * 4;
        const promises = selectedClasses?.map(async (response) => {
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          await postSaveSubmissions(mail, response.id, () => { });
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
          await postSaveForms(mail, response.id, () => { });
          completedSteps++;
          setPercentage(adjustPercentage(completedSteps, totalSteps));
        });
        try {
          await Promise.all(promises);
          setViewState(States.Iddle);
          setHasQueryFinished(true);
        } catch (e) {
          console.error(e);
          toast({
            description: t('FEEDBACKS.SUBMISSIONS_FAILED'),
            duration: 3000,
            status: "error",
            isClosable: true,
            position: "bottom",
          });
          setTimeout(() => googleLogout(mail), 3500);
        }
      }}
    />
  );
};

export default ClassSelectionModal;
