import { Button, FormControl, FormLabel, Input, Spinner, useToast } from "@chakra-ui/react";
import { ReactComponent as Logo } from "media/tutoria-logo.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";
import { post } from "../../utils/fetch";
import "./auth.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();
  const history = useHistory();

  async function requestPasswordChange(e) {
    e.preventDefault()
    try {
      setIsLoading(true)
      await post(
        "/api/auth/password_reset/",
        {
          email,
        },
        "POST"
      );
      setEmailSent(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        description: t('FEEDBACKS.FORGOT_PASSWORD'),
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      });
    }
  }

  const form = (
    <form className="auth-box" onSubmit={requestPasswordChange}>
      <div className="img-container">
        <Logo />
      </div>

      <FormControl>
        <FormLabel for="email">{t('EMAIL')}</FormLabel>
        <Input required type="email" name="email" onChange={(e) => setEmail(e.currentTarget.value)} />
      </FormControl>

      <Button
        className="main-btn"
        type="submit"
        isLoading={isLoading}
        colorScheme="rgb(247,119,97)"
        spinner={<Spinner color="#F0F0F0" thickness="4px" speed="0.65s" />}
      >
        {t('REQUEST_PASSWORD_CHANGE')}
      </Button>
      <div className="auth-switch">
        <Link to={ROUTES.REGISTER}>{t('SIGN_UP')}</Link>
      </div>
    </form>
  );

  const message = (
    <div className="auth-box">
      <p>
        {t('REQUEST_PASSWORD_CHANGE_MESSAGE')}
      </p>
      <Button className="main-btn" onClick={() => history.push("/")}>
        {t('RETURN_TO_LOGIN')}
      </Button>
    </div >
  );

  return <div className="auth">{emailSent ? message : form}</div>;
}
