import React, { useState } from "react";

import {
  Center,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react";

import { ReactComponent as Logo } from "media/tutoria-logo.svg";

import { FiMenu } from "react-icons/fi";

import "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ChangeLang from "components/ChangeLang";

function WebNavbar(navStyle) {
  const { t } = useTranslation();
  return (
    <Flex
      boxShadow={navStyle}
      zIndex={3}
      w={"full"}
      h={"75px"}
      bgColor={"gray.200"}
      sx={{
        position: "sticky",
        top: "0",
        transition: "all linear 0.2s",
      }}
    >
      <Flex width={"20%"} p={"7"} alignItems={"center"}>
        <Link href="/">
          <Logo width={"180px"} height={"22px"} fill="#225794" />
        </Link>
      </Flex>
      <Flex width={"80%"} alignItems={"center"} fontWeight={"normal"} p={"7"}>
        <Link mx={"6"} href="/#highlights">
          {t('HIGHLIGHTS')}
        </Link>
        <Link mx={"6"} href="/#integrations">
          {t('INTEGRATION')}
        </Link>
        <Link mx={"6"} href="/#about">
          {t('ABOUT')}
        </Link>
        <Link mr={"7"} ml={"auto"} justifySelf={"flex-end"} href="/login">
          {t('LOGIN')}
        </Link>
        <ChangeLang />
      </Flex>
    </Flex>
  );
};

function MobileNavbar(navStyle) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex
      boxShadow={navStyle}
      zIndex={3}
      w={"full"}
      h={"75px"}
      bgColor={"gray.200"}
      sx={{
        position: "sticky",
        top: "0",
        transition: "all linear 0.2s",
      }}
    >
      <Flex width={"20%"} p={"7"} alignItems={"center"}>
        <Link href="/">
          <Logo width={"180px"} height={"22px"} fill="#225794" />
        </Link>
      </Flex>
      <Flex width={"80%"} alignItems={"center"} fontWeight={"normal"} p={"7"}>
        <Center ml={"auto"} h={"50px"} w={"50px"}>
          <Menu isOpen={isOpen}>
            <MenuButton onClick={() => setIsOpen(!isOpen)}>
              <IconButton
                bgColor={"gray.200"}
                icon={<FiMenu color="#225794" size={"30px"} />}
              />
            </MenuButton>
            <MenuList display={!isOpen && "none"}>
              <Flex flexDir={"column"} alignItems={"center"}>
                <Link
                  onClick={() => setIsOpen(false)}
                  my={"3"}
                  href="#highlights"
                >
                  {t('HIGHLIGHTS')}
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  my={"3"}
                  href="#integrations"
                >
                  {t('INTEGRATION')}
                </Link>
                <Link onClick={() => setIsOpen(false)} my={"3"} href="#about">
                  {t('ABOUT')}
                </Link>
                <Link my={"3"} href="/login">
                  {t('LOGIN')}
                </Link>
              </Flex>
            </MenuList>
          </Menu>
        </Center>
      </Flex>
    </Flex>
  );
};

const Header = () => {
  const breakpoint = useBreakpointValue({ base: "mobile", md: "web" });
  const [navStyle, setStyle] = useState("none");

  const scrollFunction = () => {
    if (window.scrollY > 20) {
      setStyle("0 1px 10px rgba(0, 0, 0, 0.32)");
    } else {
      setStyle("none");
    }
  };
  window.addEventListener("scroll", scrollFunction);

  return breakpoint === "web" ? <WebNavbar navStyle={navStyle} /> : <MobileNavbar navStyle={navStyle} />;
};

export default Header;
