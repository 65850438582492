import React from "react";

import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

import { BiSearch } from "react-icons/bi";

const SearchBar = (props) => {
  return (
    <InputGroup
      borderRadius="10px"
      color="black"
      marginY="6"
      width="350px"
      size="sm"
      variant="filled"
    >
      <Input
        borderRadius="10px"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChangeText}
      />
      <InputRightElement
        h="32px"
        w="35px"
        color="gray.400"
        children={<BiSearch color="black.500" />}
      />
    </InputGroup>
  );
};

export default SearchBar;
