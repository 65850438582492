import React, { useState, useEffect } from "react";

import {
  Box,
  Flex,
  Text,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const AlgorithmItem = ({
  itemName = "",
  itemValue = "",
  itemCallback = () => {},
  showSlider = true
}) => {
  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(itemValue);
  const enumDescription = { 70: t("HIGH"), 80: t("MIDDLE"), 90: t("LOW") };

  useEffect(() => {
    setSliderValue(itemValue);
  }, [itemValue]);

  const handleSaveClick = (value) => {
    itemCallback(value);
  };

  return (
    <Box my="6">
      <Text fontWeight="bold" my="4">
        {itemName}
      </Text>
      <Flex alignItems="center" mb="4">
      <Text mr="2">
        {t("ACTIVATE")}
      </Text>
      <Switch id='switch-activate' onChange={(e) => { 
        if(showSlider) {
          setSliderValue(e.currentTarget.checked ? 70 : 101);
          handleSaveClick(e.currentTarget.checked ? 70 : 101)
        }
        else {
          setSliderValue(e.currentTarget.checked ? true : false);
          handleSaveClick(e.currentTarget.checked ? true : false)
        }}} 
        defaultIsChecked={showSlider ? sliderValue !== 101 : sliderValue}
      />
      </Flex>
      {showSlider && <Flex justifyContent={"space-between"} w="100%">
        <Flex justifyContent="flex-start" alignItems="center">
          <Text>{t("LOW")}</Text>
          <Box
            p="3"
            mx="4"
            borderRadius={"2xl"}
            bg="#f9f9f9"
            minW="360px"
            maxW="360px"
          >
            <Flex justifyContent="center" alignItems="center">
              <Box boxSize={2} bgColor="#BABABA" borderRadius={"lg"}></Box>
              <Slider
                isDisabled={sliderValue === 101}
                isReversed
                aria-label={`slider - ${itemName}`}
                value={sliderValue}
                min={70}
                max={90}
                step={10}
                onChange={(val) => {
                  setSliderValue(val);
                  handleSaveClick(val);
                }}
              >
                <SliderTrack color="blue.400">
                  <SliderFilledTrack bg="none" />
                </SliderTrack>
                <SliderThumb bgColor="blue.400" zIndex="999"></SliderThumb>
                <Tooltip
                  hasArrow
                  bg="blue.400"
                  color="white"
                  placement="top"
                  label={enumDescription[sliderValue]}
                >
                  <SliderThumb />
                </Tooltip>
              </Slider>
              <Box
                boxSize={2}
                bgColor="#BABABA"
                borderRadius={"lg"}
                position="absolute"
              ></Box>
              <Box boxSize={2} bgColor="#BABABA" borderRadius={"lg"}></Box>
            </Flex>
          </Box>
          <Text>{t("HIGH")}</Text>
        </Flex>
      </Flex>}
    </Box>
  );
};

export default AlgorithmItem;
