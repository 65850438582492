import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "utils/useQuery";

const ParamsMoodleContext = createContext({});

export const ParamsMoodleProvider= ({children}) => {
    const query = useQuery();
    const [coursesId, setCoursesId] = useState();
    const [action, setAction] = useState();

    useEffect(() => {
        setAction(query.get("action"));
        setCoursesId(query.get("courses_id"));
    }, [])
  
    return (
      <ParamsMoodleContext.Provider value={{action, coursesId}} >
        {children}
      </ParamsMoodleContext.Provider>
    )
  }

  export const useParamsMoodle = () => {
    const context = useContext(ParamsMoodleContext);
  
    if(!context) throw new Error('"useParamsMoodle" must be used inside a ParamsMoodleProvider.');
  
    const { action, coursesId } = context;
  
    return { action, coursesId };
  }