import { Box, Flex, useToast } from "@chakra-ui/react";
import Colors from "constants/Colors";
import EvaluationTypes from "constants/EvaluationTypes";
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useHighlightableContent, useSelectableRef } from "utils/selection";
import { EvaluationActions, EvaluationContext } from "./MyEvaluationContext";
import MyEvaluationDrawer from "./MyEvaluationDrawer";
import { v4 as uuidv4 } from "uuid";
import { post } from "utils/fetch";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";
import { useTranslation } from "react-i18next";

const MyEvaluationAnswer = () => {
  // states and refs
  const [targetRef, setTargetRef] = useState(null);
  const [newTag, setNewTag] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [newTagAdded, setNewTagAdded] = useState(false);
  const {responseId, questionId} = useNavigationCorrection();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    evaluationQuestionDescription,
    dispatch,
    updating,
    tags,
    setRecommendedTags,
    maxScore
  } = useContext(EvaluationContext);

  // helper hooks
  const [selectionRef] = useSelectableRef(
    evaluationQuestionDescription,
    (selection) => pushRange(selection)
  );

  const visibleTags = useMemo(
    () => tags.filter((e) => e.init !== null),
    [tags]
  );

  const [Content] = useHighlightableContent(
    evaluationQuestionDescription,
    visibleTags,
    selectionRef,
    {
      onClick: (ref) => {
        setTargetRef(ref);
        setShowPopover(true);
      },
      onClickRecommendation: (ref) => {
        setTargetRef(ref);
        setShowPopover(true);
      },
    }
  );

  const getFirstThreeWords = (sentence) => {
    return sentence.split(' ').filter(item => item !== "").slice(0, 3).join(" ")
  }

  // callbacks
  const pushRange = useCallback(
    (range) => {
      const shouldAdd =
        range.start !== null &&
        range.end !== null &&
        tags.every(
          (r) =>
            (range.start <= r.init && range.end <= r.init) ||
            (range.start >= r.end && range.end >= r.end)
        );
      if (shouldAdd) {
        const tag = {
          tag_name: getFirstThreeWords(range.text),
          color: Colors.default,
          type: EvaluationTypes.HIT,
          content: range.text,
          explanation: "",
          init: range.start,
          end: range.end,
          id: uuidv4(),
        };
        dispatch({ type: EvaluationActions.addRange, payload: tag });
        setNewTagAdded(true)
        setNewTag(tag)
        if(tags.length !== 0 && maxScore === null) {
          dispatch({
            type: EvaluationActions.updateEvaluation,
            payload: { score: -1 },
          });
        }
      }
    },
    [dispatch, tags]
  );

  useEffect(() => {
    if (newTagAdded) {
      setShowPopover(true)
    }
  }, [pushRange, tags, newTagAdded])

  const handleDeleteTag = async (tag) => {
    await post("api/courses/correction/ai_tags", 
      {tag_id: tag.id, answer_id: responseId}, 
      "DELETE").then(() => {
        toast({
          description: t('FEEDBACKS.DELETE_TAG_SUCCESS'),
          duration: 3000,
          status: "success",
          isClosable: true,
          position: "bottom",
        });
      }).catch(() => {
        toast({
          description: t('FEEDBACKS.DELETE_TAG_ERROR'),
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "bottom",
        });
    })
  }

  const deleteRange = useCallback(
    (range) => {
      if (range?.isRecommendation) {
        post("api/courses/correction/ai_tags", {
          question_id: questionId,
          answer_id: responseId,
          tag_id: range.id,
          accepted: false
        })
        setRecommendedTags((state) => state.filter((r) => range.id !== r.id));
        setShowPopover(false);
        setNewTagAdded(false)
        return;
      }
      handleDeleteTag(range)
      dispatch({
        type: EvaluationActions.removeRange,
        payload: { id: range.id },
      });
      setShowPopover(false);
      setNewTagAdded(false);
      if(tags.length === 1 && maxScore === null) {
        dispatch({
          type: EvaluationActions.updateEvaluation,
          payload: { score: null },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const saveRange = useCallback(() => {
    setShowPopover(false);
    setNewTagAdded(false)
  }, []);

  return (
    <Flex position="relative" width="100%">
      <Box
        my="6"
        width="100%"
        className="t-evaluation-answer"
        opacity={updating ? "0.5" : "1"}
        userSelect={updating ? "none" : "auto"}
        ref={selectionRef}
      />
      <Content />
      <MyEvaluationDrawer
        onDismiss={() => {
          setShowPopover(false)
          setNewTagAdded(false)
        }}
        visible={showPopover}
        range={newTagAdded && newTag ? newTag : targetRef?.getRange()}
        onClickDelete={deleteRange}
        onClickSave={saveRange}
      />
    </Flex>
  );
};

export default MyEvaluationAnswer;
