import React, { useState } from "react";

import * as ROUTES from "../../constants/Routes";

import "./auth.scss";
import { Link, Redirect } from "react-router-dom";
import { post } from "../../utils/fetch";
import { loginSuccess, getUserLogin } from "../../utils/user";
import { ReactComponent as Logo } from "media/tutoria-logo.svg";
import { WarningIcon } from "@chakra-ui/icons";
import { Button, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FooterGoogle from "components/FooterGoogle";

export default function Login() {
  const [form, setForm] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const errorMessages = (erro) => {
    if (
      erro === "This field is required." ||
      erro === "This field may not be blank."
    ) {
      return t('FEEDBACKS.FILL_ALL_FIELDS');
    } else if (erro === "Unable to log in with provided credentials.") {
      return t('FEEDBACKS.PASSWORD_OR_EMAIL');
    }
    return erro;
  };

  const changeForm = (e) => {
    const updatedForm = { ...form, [e.target.name]: e.target.value };
    setForm(updatedForm);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let form_sha = Object.assign({}, form);

      const { token } = await post("/api/auth/login/", form_sha);
      const success = await loginSuccess(token);
      if (success) {
        setLoggedIn(true);
      }
    } catch (e) {
      setIsLoading(false);
      setErrors(Object.values(e));
    }
  };

  if (loggedIn || getUserLogin()) {
    window.location.pathname = "/";
    return <Redirect to={"/"} />;
  }

  return (
    <div className="auth">
      <form className="auth-box">
        <div className="img-container">
          <Logo />
        </div>

        <div className="form-section">
          <label>{t('EMAIL')}</label>
          <input
            autoComplete="username email"
            aria-invalid="false"
            id="email"
            name="username"
            type="text"
            aria-label="email input"
            aria-required="true"
            required
            className
            value={form.username}
            onChange={changeForm}
            placeholder="Ex.: tutoria@exemplo.com"
          />
        </div>

        <div className="form-section">
          <label>{t('PASSWORD')}</label>
          <input
            autoComplete="current-password"
            aria-invalid="false"
            name="password"
            type="password"
            aria-required="true"
            required
            className=""
            value={form.password}
            onChange={changeForm}
            placeholder="********"
          />
        </div>
        {errors.length > 0 ? (
          <div className="error-box">
            <WarningIcon marginBottom="5px" marginRight="5px" />
            {errorMessages(errors[0].toString())}
          </div>
        ) : null}
        <Button
          className="main-btn"
          onClick={sendForm}
          isLoading={isLoading}
          type="submit"
          colorScheme="rgb(247,119,97)"
          spinner={<Spinner color="#F0F0F0" thickness="4px" speed="0.65s" />}
        >
          {t('SIGN_IN')}
        </Button>
        <div className="auth-switch">
          <Link to={ROUTES.REGISTER}>{t('SIGN_UP')}</Link>
          <Link to={ROUTES.FORGOT_PASSWORD}>{t('FORGOT_PASSWORD')}</Link>
        </div>
      </form>
      <FooterGoogle position="absolute" />
    </div>
  );
}
