/* eslint-disable */
import QuestionTypes from "constants/QuestionTypes";
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { post } from "utils/fetch";

import { saveInfoToStorage } from "../../service/SideBarLocalStorage";

export const EvaluationContext = createContext({});

export const EvaluationActions = {
  addRange: "add_range",
  removeRange: "remove_range",
  updateRange: "update_range",
  initPayload: "init_payload",
  updateEvaluation: "update_evaluation",
};

const EvaluationContextProvider = ({ rootProps, children }) => {
  const [workId, setWorkId] = useState("");
  const [formId, setFormId] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [maxScore, setMaxScore] = useState(null);
  const [score, setScore] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [evaluationNumber, setEvaluationNumber] = useState(null);
  const [studentProfilePhoto, setStudentProfilePhoto] = useState("");
  const [evaluationQuestionTitle, setEvaluationQuestionTitle] = useState("");
  const [evaluationQuestionNumber, setEvaluationQuestionNumber] = useState("");
  const [evaluationQuestionDescription, setEvaluationQuestionDescription] =
    useState("");
  const [tags, setTags] = useState([]);
  const [recommendedTags, setRecommendedTags] = useState([]);
  const [possibleCopies, setPossibleCopies] = useState([]);

  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [choicesFeedback, setChoicesFeedback] = useState([]);
  const [correctAnswersIndex, setCorrectAnswersIndex] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const {
    page,
    id,
    activityId,
    viewOption,
    responseId,
    questionId,
    responseData,
  } = history.location.state;

  useEffect(() => {
    saveInfoToStorage("correction-history", history.location.state);
  }, []);

  const { activityNumber } = rootProps;

  const [evaluation, dispatch] = useReducer((state, { type, payload }) => {
    const actions = {
      [EvaluationActions.addRange]: (payload) => ({
        ...state,
        tags: [{ ...payload }, ...state.tags].sort(
          (r, s) => r.init - s.init
        ),
      }),

      [EvaluationActions.removeRange]: (payload) => ({
        ...state,
        tags: state.tags
          .filter((e) => e.id !== payload.id)
          .sort((r, s) => r.init - s.init),
      }),

      [EvaluationActions.updateRange]: (payload) => ({
        ...state,
        tags: state.tags.map((tag) => {
          if (tag.id === payload.id) return { ...tag, ...payload };
          return tag;
        }),
      }),

      [EvaluationActions.updateEvaluation]: (payload) => ({
        ...state,
        ...payload,
      }),

      [EvaluationActions.initPayload]: (payload) => {
        const possibleChoices = payload.possible_choices
          ? JSON.parse(payload.possible_choices?.replace(/'/g, '"'))
          : [];

        const feedbacks =
          possibleChoices?.length !== payload.choices_feedback?.length
            ? possibleChoices.map(() => "")
            : payload.choices_feedback;

        setCorrectAnswers(payload.correct_answer);
        setCorrectAnswersIndex(payload.correct_answer_index);
        setChoicesFeedback(feedbacks);

        return {
          score: payload.score,
          response_id: payload.response_id,
          question_id: payload.question_id,
          html: payload.html,
          tags: payload.tags?.sort((r, s) => r.init - s.init) ?? [],
          possible_choices: possibleChoices,
          correct_answer: payload.correct_answer,
          answer: payload.answer,
        };
      },
    };

    return actions[type]?.(payload) ?? state;
  }, {});

  useEffect(() => {
    let _tags = [...(evaluation.tags || [])];
    let _recommended = recommendedTags.filter((tag) =>
      _tags.every(
        (r) =>
          (tag.init <= r.init && tag.end <= r.init) ||
          (tag.init >= r.end && tag.end >= r.end)
      )
    );
    setTags([..._tags, ..._recommended].sort((r, s) => r.init - s.init));
  }, [evaluation.tags, recommendedTags]);

  const performUpdate = useCallback(() => {
    if (evaluation.question_id && evaluation.response_id) {
      evaluation.tags.forEach((tag) => {
        if (!tag.content && tag.content_to_mark) {
          tag.content = tag.content_to_mark;
        }
      });

      setUpdating(true);
      post("api/courses/correction/error/update/", evaluation)
        .then((res) => {
          setTags((tags) => {
            let newTags = [...tags];
            res.markers.forEach((mark) => {
              const index = tags.findIndex((tag) => tag.id === mark.id);
              if (index === -1) {
                newTags.push(mark);
              } else {
                newTags[index] = mark;
              }
            });
            return newTags;
          });
        })
        .catch((err) => {
          setErrorMessage(err);
        })
        .finally(() => setUpdating(false));
    }
  }, [evaluation]);

  useEffect(() => {
    if (questionType === QuestionTypes.MULTIPLE_CHOICE) {
      setUpdating(true);
      post("api/courses/correction/multiplechoice/", {
        question_id: window.localStorage.getItem("questionId"),
        response_id: window.localStorage.getItem("responseId"),
        choices_feedback: choicesFeedback,
        correct_answer: correctAnswers,
        correct_answer_index: correctAnswersIndex,
      }).catch((err) => {
        setErrorMessage(err);
      });
    }
  }, [
    correctAnswers,
    correctAnswersIndex,
    choicesFeedback
  ]);

  useEffect(() => {
    performUpdate();
  }, [performUpdate]);

  const updateCorrectAnswers = useCallback(
    (indexes) => {
      setCorrectAnswersIndex(indexes);
      setCorrectAnswers(indexes.map((i) => evaluation.possible_choices[i]));
    },
    [evaluation.possible_choices]
  );

  // r, res
  const init = useCallback((userData, correction) => {
    setFormId(correction.data.form_id);
    setEvaluationNumber(correction.data.work_name);
    setAllTags(correction.data.all_tags ?? []);
    setStudentName(correction.data.name || "Estudante não cadastrado");
    setStudentProfilePhoto(`https:${correction.data.photo_url}`);
    setEvaluationQuestionNumber(String(correction.data.question_number));
    setEvaluationQuestionTitle(correction.data.title || "Questão sem título");
    setMaxScore(correction.data.max_points);
    setScore(correction.data.score);
    setEvaluationQuestionDescription(
      correction.data.html || correction.data.answer
    );
    setQuestionType(correction.data.question_type);
    setWorkId(correction.data.work_id);
    setPossibleCopies(correction.data.possible_copies);
    setChoicesFeedback(correction.data.choices_feedback);
    dispatch({
      type: EvaluationActions.initPayload,
      payload: { ...correction.data },
    });

    if (correction.data.question_type === QuestionTypes.PARAGRAPH_TEXT) {
      const splitAnswer = correction.data.answer.split(" ");
      const recommendedTags = correction.data.recommended_tags
        .sort((a, b) => a.init - b.init)
        .map((tag) => {
          let startOffset = 0;
          let endOffset = 0;
          splitAnswer.some((e, i) => {
            if (i === tag.end) {
              endOffset += e.length + 1;
              return true;
            }
            if (i < tag.init) {
              startOffset += e.length + 1;
            }
            endOffset += e.length + 1;
            return false;
          });

          if (tag.end) endOffset = tag.end;

          if (tag.init) startOffset = tag.init;

          return {
            ...tag,
            init: startOffset,
            end: endOffset,
            isRecommendation: true,
          };
        });
      setRecommendedTags(recommendedTags);
    }
  }, []);

  return (
    <EvaluationContext.Provider
      value={{
        init,
        dispatch,
        setFormId,
        performUpdate,
        setRecommendedTags,
        setChoicesFeedback,
        updateCorrectAnswers,
        possibleCopies,
        id,
        page,
        activityId,
        viewOption,
        responseId,
        questionId,
        responseData,
        correctAnswers,
        choicesFeedback,
        correctAnswersIndex,
        tags,
        formId,
        allTags,
        maxScore,
        score,
        setScore,
        updating,
        errorMessage,
        evaluation,
        studentName,
        questionType,
        activityNumber,
        evaluationNumber,
        studentProfilePhoto,
        evaluationQuestionTitle,
        evaluationQuestionNumber,
        evaluationQuestionDescription,
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};
export default EvaluationContextProvider;
