import { Box } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

// handles all the logic to detect and notify
// a user selection.
const useSelectableRef = (text, onSelect) => {
  const ref = useRef(null);

  const onPossibleSelect = useCallback(() => {
    const _selection = window.getSelection();
    if (_selection.rangeCount <= 0) return;

    const range = _selection.getRangeAt(0);
    const startContainer = range.startContainer.parentElement;
    const endContainer = range.endContainer.parentElement;

    // if selection is not within the same parent, does nothing
    if (startContainer !== endContainer) {
      return;
    }

    // if length of the selection is 0, does nothing
    if (range.startOffset === range.endOffset) {
      return;
    }

    let globalOffset = 0;
    for (const e of ref.current.childNodes) {
      if (e.id === startContainer.id) break;
      globalOffset += e.innerHTML.length;
    }

    const startOffset = globalOffset + range.startOffset;
    const endOffset = globalOffset + range.endOffset;
    onSelect({
      text: text.substring(startOffset, endOffset),
      start: startOffset,
      end: endOffset,
    });
  }, [onSelect, text]);

  useEffect(() => {
    ref.current.ondbclick = onPossibleSelect;
    ref.current.onmouseup = onPossibleSelect;
  }, [onPossibleSelect]);

  // useDebugValue(ref.current);
  return [ref];
};

const getContentWithSpans = (
  str,
  { onClick, onClickRecommendation },
  ranges
) => {
  let _str = "";
  let range = ranges.shift();
  let elements = [];

  let i = 0;
  let start = 0;
  while (i < str.length) {
    if (!range) {
      _str = str.slice(i, str.length);
      elements.push(
        <Section key={elements.length} id={elements.length}>
          {_str}
        </Section>
      );
      break;
    }

    if (range.init === i) {
      _str = str.slice(start, i);
      elements.push(
        <Section key={elements.length} id={elements.length}>
          {_str}
        </Section>
      );
      i += range.end - range.init;
      start = i;
      _str = str.slice(range.init, range.end);
      elements.push(
        <SelectedSection
          key={elements.length}
          id={elements.length}
          onClick={onClick}
          onClickRecommendation={onClickRecommendation}
          range={range}
        >
          {_str}
        </SelectedSection>
      );
      range = ranges.shift();
      continue;
    }
    i++;
  }
  return elements;
};

// handles all the logic to add highlightable spans
// to a content without direct DOM manipulation.
const useHighlightableContent = (initialValue, ranges, reference, props) => {
  const [contentString, setContentString] = useState("");

  const Content = () => {
    const content = useMemo(
      () => getContentWithSpans(contentString, props, [...ranges]),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contentString, ranges]
    );
    return <>{content}</>;
  };

  const render = useCallback(() => {
    return (
      reference.current && ReactDOM.createPortal(<Content />, reference.current)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, contentString, ranges]);

  useLayoutEffect(() => {
    setContentString(initialValue);
  }, [initialValue]);

  return [render];
};

const Section = ({
  children,
  id,
  className,
  onClick,
  selected,
  range,
  ...props
}) => {
  const elementRef = useRef(null);

  const getRange = useCallback(() => range, [range]);
  const getElement = useCallback(() => elementRef.current, [elementRef]);

  const ref = useMemo(() => ({ getRange, getElement }), [getElement, getRange]);

  return (
    <Box
      display="inline"
      {...props}
      id={`selectable-span-${id}`}
      onClick={selected ? () => onClick?.(ref) : () => {}}
      ref={elementRef}
    >
      {children}
    </Box>
  );
};

const SelectedSection = ({
  children,
  id,
  onClick,
  onClickRecommendation,
  range,
}) => {
  if (range.isRecommendation) {
    return (
      <Section
        id={id}
        borderBottom="3px"
        borderStyle="solid"
        borderColor={range.color || "gray.300"}
        _hover={{ backgroundColor: `${range.color}55` || "gray.100" }}
        range={range}
        onClick={onClickRecommendation}
        cursor="pointer"
        selected
      >
        {children}
      </Section>
    );
  }
  return (
    <Section
      id={id}
      borderRadius="xl"
      backgroundColor={range.color || "gray.300"}
      color="white"
      padding="0 0.5em"
      cursor="pointer"
      onClick={onClick}
      range={range}
      selected
    >
      {children}
    </Section>
  );
};

export { useSelectableRef, useHighlightableContent };
