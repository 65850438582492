import { useHistory } from "react-router-dom";
import { QUESTION } from "constants/Routes";
import { Button } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

export default function ButtonBackOverview() {
    const history = useHistory();
    const { t } = useTranslation();
    const activityId = history?.location?.state?.activityId;
    const workId = history?.location?.state?.workId;
    const email = history?.location?.state?.email;
    const id = history?.location?.state?.id;
    return <Button leftIcon={<ArrowBackIcon />} colorScheme='blue' variant='link' mb={2} onClick={() => {
        history.push({
            pathname: QUESTION,
            state: {
                activityId: activityId,
                workId: workId,
                email: email,
                id: id,
            },
        });
    }}>
        {t('BACK_TO_OVERVIEW')}
    </Button>
}